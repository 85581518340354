import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-table-widgets',
  templateUrl: './placeholder-table-widgets.component.html',
  styleUrls: ['./placeholder-table-widgets.component.css']
})
export class PlaceholderTableWidgetsComponent {

}
