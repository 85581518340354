<div class="wrapper skeleton">
    <div class="form">
        <div class="bone form__field"></div>

        <div class="bone form__string"></div>
        <div class="bone form__string"></div>
    </div>


    <div class="actions">
        <div class="bone form__button"></div>
        <div class="bone form__button"></div>
    </div>
</div>
