<div class="wrapper skeleton">
    <div class="row-edit-header">
        <div class="bone row-edit-header__breadcrumb row-edit-header__breadcrumb_1"></div>
        <mat-icon>chevron_right</mat-icon>
        <div class="bone row-edit-header__breadcrumb row-edit-header__breadcrumb_2"></div>
        <mat-icon>chevron_right</mat-icon>
        <div class="bone row-edit-header__breadcrumb row-edit-header__breadcrumb_3"></div>
    </div>
    <div class="form">
        <div class="bone form__field"></div>
        <div class="bone form__addButton"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>

        <div class="bone form__field"></div>
        <div class="bone form__info"></div>
    </div>
    <div class="actions">
        <div class="bone actions__button actions__button_left"></div>
        <div class="bone actions__button"></div>
        <div class="bone actions__button"></div>
    </div>
</div>