<h1 mat-dialog-title>Confirm <span class="actions-title">{{data.title}}</span> for {{ data.tableDisplayName }} table</h1>
<mat-dialog-content>
    <p *ngIf="data.identityFieldValue; else primaryKeysList">
        You are going to <strong class="actions-title">{{data.title}}</strong> for <strong>{{data.identityFieldValue}}</strong>.
    </p>
    <ng-template #primaryKeysList>
        <p class="mat-body">You are going to <strong class="actions-title">{{data.title}}</strong> with the following primary key(s)
            <strong *ngFor="let primaryKey of data.primaryKeys | keyvalue; let last = last">
                {{primaryKey.key}} = {{primaryKey.value}}<span *ngIf="!last">,</span>
            </strong>.
        </p>
    </ng-template>
    <br />
    <p class="mat-body">Please confirm.</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="warn"
        attr.data-testid="{{data.id ? 'table-apply-action-dialog-button' : 'table-delete-record-dialog-button'}}"
        [disabled]="submitting"
        (click)="handleConfirmedAction()" [disabled]="submitting">
        {{data.title}}
    </button>
</mat-dialog-actions>