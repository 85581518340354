import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-user-groups',
  templateUrl: './placeholder-user-groups.component.html',
  styleUrls: ['./placeholder-user-groups.component.css']
})
export class PlaceholderUserGroupsComponent {

}
