<app-placeholder-table-view *ngIf="loading"></app-placeholder-table-view>

<app-banner *ngIf="isServerError" type="error" data-testid="connection-server-error-banner">
    <div *ngIf="serverError.details; else stringError" class="mat-body-1">
        <strong>{{serverError.abstract}}</strong>
        <p class="mat-body-1 error-details">{{serverError.details}}</p>
    </div>
    <ng-template #stringError>
        <p class="mat-body-1">{{serverError.abstract}}</p>
    </ng-template>
    <div class="error-actions">
            <a mat-stroked-button routerLink="/edit-db/{{connectionID}}">Connection settings</a>
            <button mat-flat-button color="warn" (click)="openIntercome()">Chat with support</button>
    </div>
</app-banner>

<app-banner *ngIf="noTablesError" type="info" data-testid="no-tables-error-banner">
    <h3 class='mat-subheading-2'>Rocketadmin can not find any tables</h3>
    <p class="mat-body-1">
        Rocketadmin don't have access to connection tables or there aren't any.
        Please grant us access to required tables or create new table.
        You can create a table with SQL editor
    </p>
</app-banner>

<ng-container *ngIf="!loading && !isServerError && !noTablesError">
    <mat-sidenav-container>
        <mat-sidenav #sideList mode="side" [(opened)]="shownTableTitles"
            class="table-list-sidenav"
            [ngClass]="{'side-bar_collapsed': !shownTableTitles}">
            <div class="toggle-button-position"
                [ngClass]="(shownTableTitles)?'toggle-button-position__opened':'toggle-button-position__closed'">
                <button mat-button type="button" class="toggle-button" (click)="toggleSideBar()"
                    angulartics2On="click"
                    angularticsAction="Dashboard: toggle sidebar"
                    [angularticsProperties]="{'collapsed': !shownTableTitles}">
                    <mat-icon>{{ shownTableTitles ? 'chevron_left' : 'chevron_right' }} </mat-icon>
                </button>
            </div>
            <app-db-tables-list
                [collapsed]="!shownTableTitles"
                [tables]="tablesList"
                [connectionID]="connectionID"
                [connectionTitle]="currentConnectionTitle"
                [selectedTable]="selectedTableName"
                (closeRowPreview)="closeRowPreview()">
            </app-db-tables-list>
        </mat-sidenav>
        <mat-sidenav-content class="table-preview">
            <div class="table-preview-content">
                <app-alert class="server-alert"></app-alert>
                <div class="alerts">
                    <app-alert *ngIf="dataSource.alert_settingsInfo" [alert]="dataSource.alert_settingsInfo"></app-alert>
                    <app-alert *ngIf="dataSource.alert_primaryKeysInfo" [alert]="dataSource.alert_primaryKeysInfo"></app-alert>
                    <app-alert *ngIf="dataSource.alert_widgetsWarning" [alert]="dataSource.alert_widgetsWarning"></app-alert>
                </div>

                <app-content-loader *ngIf="dataSource === null"></app-content-loader>
                <app-db-table *ngIf="dataSource"
                    [displayName]="selectedTableDisplayName"
                    [activeFilters]="filters"
                    [filterComparators]="comparators"
                    [name]="selectedTableName"
                    [table]="dataSource"
                    [rowSelection]="selection"
                    [connectionID]="connectionID"
                    [accessLevel]="currentConnectionAccessLevel"
                    (openFilters)="openTableFilters($event)"
                    (removeFilter)="removeFilter($event)"
                    (resetAllFilters)="clearAllFilters()"
                    (search)="search($event)"
                    (viewRow)="viewRow($event)"
                    (activateAction)="activateAction($event)"
                    (activateActions)="activateActions($event)">
                </app-db-table>
            </div>
            <app-db-table-row-view *ngIf="selectedRow"
                [selectedRow]="selectedRow"
                [columns]="dataSource.columns"
                [foreignKeys]="dataSource.foreignKeys"
                [foreignKeysList]="dataSource.foreignKeysList"
                (close)="closeRowPreview()"
            ></app-db-table-row-view>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
