<div class="placeholder">
    <div class="sidebar"></div>
    <div class="skeleton table-view">
        <div class="table-header">
            <div class="bone table-header__title"></div>
            <div class="bone table-header__search"></div>
            <div class="bone table-header__button"></div>
            <div class="bone table-header__button"></div>
            <div class="bone table-header__button"></div>
            <div class="bone table-header__icon-button"></div>
        </div>
        <app-placeholder-table-data></app-placeholder-table-data>
    </div>
</div>
