<h1 mat-dialog-title>Confirm widget delete</h1>
<mat-dialog-content>
    <p *ngIf="widgetFieldName; else clearAllMessage" class="mat-body">
        You are going to delete widget for <strong>{{ widgetFieldName }}</strong> field.
    </p>
    <ng-template #clearAllMessage>
      <p>Do you want to delete all widgets?</p>
    </ng-template>
    <br>
    <p class="mat-body">Please confirm.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="warn" mat-dialog-close="delete">Delete</button>
</mat-dialog-actions>
