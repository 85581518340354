<app-alert></app-alert>

<div class="wrapper background-decoration">
    <div class="login-page">
        <form class="login-form"
            [hidden]="is2FAShown"
            #loginForm="ngForm"
            (ngSubmit)="user.companyId && user.password ? loginUser() : requestUserCompanies()">

            <div class="login-header">
                <svg class="login-header__logo" width="56" height="54" viewBox="0 0 56 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M47.2367 17.9125H35.1499C33.3671 17.9125 32.4833 16.5678 33.1783 14.9286L37.5295 4.71527C38.5191 2.39612 37.2651 0.5 34.742 0.5H23.4711C22.3682 0.5 21.1142 1.33097 20.6836 2.34323L15.4485 14.634C14.5798 16.6661 15.6827 18.328 17.8885 18.328H29.4465C30.7231 18.328 31.8789 19.0909 32.3775 20.2618L37.5295 32.3486C38.232 33.9954 37.3406 35.3401 35.5578 35.3401H8.62703C7.52411 35.3401 6.27011 36.1711 5.83952 37.1833L0.823517 48.9453C-0.166086 51.2644 1.08792 53.1605 3.61103 53.1605H29.8242C30.9271 53.1605 32.1811 52.3296 32.6117 51.3173L38.4435 37.629C38.9346 36.4808 40.0526 35.7405 41.299 35.7405H52.6833C54.9722 35.7405 56.1053 34.0181 55.2064 31.918L50.0242 19.7633C49.5936 18.751 48.3396 17.92 47.2367 17.92V17.9125Z" fill="#08041B"/>
                </svg>
                <h1 class="mat-headline-4 loginTitle">
                    Welcome back to <br>
                    <span class="loginTitle__emphasis">Rocketadmin</span>
                </h1>
                <div *ngIf="!userCompanies" class="mat-body-1">
                    To log in, please start by entering your email address and click next to continue.
                </div>
                <div *ngIf="userCompanies && userCompanies.length > 1" class="mat-body-1">
                    Please select the company or organization you wish to log into and click next to continue.
                </div>
            </div>

            <mat-form-field class="login-form__email">
                <mat-label>Email</mat-label>
                <input matInput type="email" name="email" emailValidator
                    data-testid="login-email-input"
                    #email="ngModel" required
                    angulartics2On="change"
                    angularticsAction="Login: email is changed"
                    [(ngModel)]="user.email" >
                <mat-error *ngIf="email.errors?.isInvalidEmail">Invalid email format.</mat-error>
            </mat-form-field>

            <div *ngIf="isLoadingUserCompanies" class="login-form__field-loader"></div>

            <mat-form-field appearance="fill" *ngIf="userCompanies && userCompanies.length > 1" class="login-form__companies">
                <mat-label>Company</mat-label>
                <mat-select [(ngModel)]="user.companyId">
                  <mat-option *ngFor="let company of userCompanies; let i = index" [value]="company.id">
                    {{company.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="isPasswordFieldShown || user.companyId" class="login-form__password">
                <mat-label>Password</mat-label>
                <input matInput type="password" name="password" data-testid="login-password-input"
                    #password="ngModel" required
                    angulartics2On="change"
                    angularticsAction="Login: password is changed"
                    [(ngModel)]="user.password">
                <!-- <mat-error *ngIf="port.errors?.required && (port.invalid && port.touched)">Port should not be empty.</mat-error> -->
            </mat-form-field>

            <div class="divider"><span class="divider__label">or</span></div>

            <div id="google_login_button" data-testid="login-google-button"
                class="login-form__google-button"
                angulartics2On="click"
                angularticsAction="Login: login with google is clicked">
            </div>

            <!--<div class="login-form__github-button-box"
                matTooltip="Coming soon"
                matTooltipPosition="above">
                <button disabled type="button" mat-stroked-button color="primary" data-testid="login-github-button"
                    class="login-form__github-button"
                    angulartics2On="click"
                    angularticsAction="Login: login with github is clicked">
                    <svg class="login-form__github-icon" viewBox="0 0 98 96" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" fill="#24292f"/></svg>
                    <span class="login-form__github-caption">Continue with GitHub</span>
                </button>
            </div>-->

            <button type="button" mat-stroked-button color="primary" data-testid="login-github-button"
                class="login-form__github-button-box login-form__github-button"
                angulartics2On="click"
                angularticsAction="Login: login with github is clicked"
                (click)="loginWithGithub()">
                <svg class="login-form__github-icon" viewBox="0 0 98 96" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" fill="#24292f"/></svg>
                <span class="login-form__github-caption">Continue with GitHub</span>
            </button>

            <!--<div class="login-form__github-button-box"
                matTooltip="Coming soon"
                matTooltipPosition="above">
                <button disabled type="button" mat-stroked-button color="primary" data-testid="login-github-button"
                    class="login-form__github-button"
                    angulartics2On="click"
                    angularticsAction="Login: login with github is clicked">
                    <svg class="login-form__github-icon" viewBox="0 0 98 96" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" fill="#24292f"/></svg>
                    <span class="login-form__github-caption">Continue with GitHub</span>
                </button>
            </div>-->

            <div class="login-form__sso-button-box"
                matTooltip="Coming soon"
                matTooltipPosition="above">
                <button disabled type="button" mat-stroked-button color="primary" data-testid="login-github-button"
                    class="login-form__sso-button"
                    angulartics2On="click"
                    angularticsAction="Login: login with github is clicked">
                    <svg class="login-form__sso-icon" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 8.21393H14.625V5.96393C14.625 2.85893 12.105 0.338928 9 0.338928C5.895 0.338928 3.375 2.85893 3.375 5.96393V8.21393H2.25C1.0125 8.21393 0 9.22643 0 10.4639V21.7139C0 22.9514 1.0125 23.9639 2.25 23.9639H15.75C16.9875 23.9639 18 22.9514 18 21.7139V10.4639C18 9.22643 16.9875 8.21393 15.75 8.21393ZM9 18.3389C7.7625 18.3389 6.75 17.3264 6.75 16.0889C6.75 14.8514 7.7625 13.8389 9 13.8389C10.2375 13.8389 11.25 14.8514 11.25 16.0889C11.25 17.3264 10.2375 18.3389 9 18.3389ZM12.4875 8.21393H5.5125V5.96393C5.5125 4.04018 7.07625 2.47643 9 2.47643C10.9237 2.47643 12.4875 4.04018 12.4875 5.96393V8.21393Z" fill="#212121"/>
                    </svg>
                    <span class="login-form__sso-caption">Continue with SSO</span>
                </button>
            </div>

            <button *ngIf="!user.companyId"
                type="submit" mat-stroked-button
                data-testid="login-next-button"
                class="login-form__login-button"
                [disabled]="submitting || user.email === ''">
                {{ submitting ? 'Submitting' : 'Next'}}
            </button>

            <button *ngIf="isPasswordFieldShown || user.companyId"
                type="submit" mat-flat-button color="accent"
                data-testid="login-button"
                class="login-form__login-button"
                angulartics2On="click"
                angularticsAction="Login: login is clicked"
                [disabled]="submitting || loginForm.form.invalid || loginForm.form.pristine">
                {{ submitting ? 'Submitting' : 'Log in'}}
            </button>

            <a routerLink="/forget-password" class="login-form__link" data-testid="forgot-password-link">Can’t Log In?</a>
        </form>

        <form *ngIf="is2FAShown">
            <div class="qr-verification">
                <h2 class="mat-headline-4 qr-verification__title">Enter 2nd factor code</h2>
                <mat-form-field class="user-settings__name">
                    <mat-label>Enter the code from your authenticator</mat-label>
                    <input matInput [(ngModel)]="authCode" name="code" #codeField="ngModel">
                </mat-form-field>
                <button type="submit" mat-flat-button color="primary"
                    [disabled]="submitting"
                    (click)="loginWith2FA()">
                    Verify
                </button>
            </div>
        </form>
    </div>
</div>

