import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-table-settings',
  templateUrl: './placeholder-table-settings.component.html',
  styleUrls: ['./placeholder-table-settings.component.css']
})
export class PlaceholderTableSettingsComponent {

}
