<h1 mat-dialog-title>Confirm <span class="actions-title">{{data.title}}</span> for {{ data.tableDisplayName }} table</h1>
<mat-dialog-content>
    <div *ngIf="data.identityFieldValues; else primaryKeysList">
        <p class="mat-body">You are going to <strong class="actions-title">{{data.title}}</strong> for:</p>
        <ul *ngIf="data.identityFieldValues; else primaryKeysList" class="listOfPrimaryKeys">
            <li *ngFor="let identityValue of data.identityFieldValues; let last = last">
                <strong>
                    {{identityValue}}<span *ngIf="!last; else dot">,</span>
                    <ng-template #dot>
                        <span>.</span>
                    </ng-template>
                </strong>
            </li>
        </ul>
    </div>
    <ng-template #primaryKeysList>
        <p class="mat-body">You are going to <strong class="actions-title">{{data.title}}</strong> with following primary key(s)</p>
        <ul class="listOfPrimaryKeys">
            <li *ngFor="let primaryKey of data.primaryKeys; let i = index">
                <strong *ngFor="let attribute of primaryKey | keyvalue; let last = last">
                    {{attribute.key}} = {{attribute.value}}<span *ngIf="!last">,</span>
                </strong>
                <span *ngIf="i !== data.primaryKeys.length - 1; else dot">;</span>
                <ng-template #dot>
                    <span>.</span>
                </ng-template>
            </li>
        </ul>
    </ng-template>
    <br />
    <p class="mat-body">Please confirm.</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="warn" (click)="handleConfirmedActions()" [disabled]="submitting">{{data.title}}</button>
</mat-dialog-actions>