<h1 mat-dialog-title>Export</h1>
<form #exportTableForm="ngForm" (submit)="exportCSV()" class="form">
    <mat-dialog-content>
        <p class="mat-body-1">
            Select number of records you want to download.
        </p>

        <mat-radio-group
            name="selectedPages"
            class="radio-group"
            [(ngModel)]="recordsExportType">
            <mat-radio-button value='export-records-number' class="radio-button">
                <mat-form-field appearance="outline" class="records-number-input">
                    <mat-label>Number of records</mat-label>
                    <input matInput type="number" name="records-number" #recordsNumberInput="ngModel"
                        [disabled]="submitting || recordsExportType !== 'export-records-number'"
                        [(ngModel)]="recordsNumber">
                </mat-form-field>
            </mat-radio-button>
            <mat-radio-button value='export-all' class="radio-button">
                Export all (up to 100 000 records)
            </mat-radio-button>
        </mat-radio-group>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-flat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button type="" color="primary" mat-dialog-close="export">
            Export
        </button>
    </mat-dialog-actions>
</form>
