
<!-- <pre>{{ value }}</pre> -->

<div class="foreign-key">
    <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{normalizedLabel}}</mat-label>
        <mat-spinner *ngIf="fetching" class="loader" diameter="20"></mat-spinner>
        <input type="text" matInput
            [required]="required" [disabled]="disabled" [readonly]="readonly"
            [(ngModel)]="currentDisplayedString"
            [matAutocomplete]="auto"
            (ngModelChange)="autocmpleteUpdate.next($event)">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="updateRelatedLink($event)">
            <mat-option *ngFor="let suggestion of suggestions"
                [ngClass]="{'disabled': suggestion.displayString === 'No matches'}"
                [value]="suggestion.displayString">
                {{suggestion.displayString}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <a routerLink="/dashboard/{{connectionID}}/{{relations.referenced_table_name}}/entry"
        [queryParams]="currentFieldQueryParams"
        target="_blank"
        class="foreign-key__link">
        <mat-icon
            class="foreign-key__link-icon"
            matTooltip="Show related record"
            matTooltipPosition="above">
            open_in_new
        </mat-icon>
    </a>
</div>



<!-- <pre>{{ relations | json }}</pre> -->
<!-- <pre>{{ suggestions | json}}</pre> -->
<!-- <p>foreign-key component</p> -->
