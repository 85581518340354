<div class="wrapper">
    <div class="skeleton">
        <div class="bone title"></div>

        <div class="bone form__string"></div>
        <div class="bone form__field"></div>

        <mat-divider class="form-divider"></mat-divider>

        <div class="bone form__string"></div>
        <div class="bone form__field"></div>

        <div class="bone form__button"></div>
    </div>
</div>
