<mat-form-field class="select-form-field" appearance="outline">
    <mat-label>{{normalizedLabel}}</mat-label>
    <mat-select name="{{label}}-{{key}}"
        [required]="required" [disabled]="disabled" [readonly]="readonly"
        attr.data-testid="record-{{label}}-select"
        [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
        <mat-option *ngFor="let option of options"
            [value]="option.value">
            {{option.label}}
        </mat-option>
    </mat-select>
</mat-form-field>
