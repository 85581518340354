<h1 mat-dialog-title>Please enter your master password</h1>
<form #enterPasswordForm="ngForm">
    <mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" name="password" #passwordField="ngModel" required>
            <mat-error *ngIf="passwordField.errors?.required && (passwordField.invalid && passwordField.touched)">Password should not be empty.</mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <a mat-stroked-button
            routerLink="/connections-list"
            (click)="closeDialog()">
            Connections list
        </a>
        <button mat-flat-button color="primary"
            [disabled]="enterPasswordForm.form.invalid"
            (click)="enterMasterPassword()">
                Enter
        </button>
    </mat-dialog-actions>
</form>