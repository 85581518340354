<div class="wrapper skeleton">
    <div class="form">
        <div class="form__line form__field_full">
            <div class="bone form__logo"></div>
            <div class="bone form__field"></div>
        </div>

        <div class="bone form__field form__field_full"></div>


        <div class="bone form__field"></div>
        <div class="bone form__field"></div>


        <div class="bone form__field form__field_full"></div>
    </div>
    <div class="actions">
        <div class="bone actions__button actions__button_left"></div>
        <div class="bone actions__button"></div>
    </div>
</div>