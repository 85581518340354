import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-add-user-dialog',
  templateUrl: './placeholder-add-user-dialog.component.html',
  styleUrls: ['./placeholder-add-user-dialog.component.css']
})
export class PlaceholderAddUserDialogComponent {

}
