export default [
'0D',
'1AM',
'1D',
'2AM',
'2D',
'2G',
'2LA',
'2Lt',
'3AM',
'3D',
'3G',
'3i',
'3M',
'4D',
'4G',
'4GL',
'4WD',
'5D',
'6D',
'7D',
'8D',
'9D',
'A1C',
'A4',
'A10',
'aa',
'aA',
'AA',
'AAA',
'AAAI',
'AAAS',
'AAAV',
'AAC',
'AAD',
'AADD',
'AADT',
'AADAC',
'AADC',
'AAFC',
'AAFES',
'AAI',
'AAIS',
'AAM',
'AAMOF',
'AAN',
'AANEAS',
'AAODL',
'AAP',
'AAPC',
'aar',
'AAR',
'AARP',
'AAS',
'AASPEM',
'AAT',
'AAV',
'AAWC',
'ab',
'AB',
'ABA',
'ABB',
'ABBA',
'ABC',
'ABCA',
'ABCS',
'ABET',
'ABFIST',
'ABGI',
'ABIN',
'abk',
'ABL',
'ABLE',
'ABM',
'ABMC',
'ABN',
'ABPI',
'ABRSM',
'ABS',
'ABSA',
'ABSTRIPS',
'ABV',
'ABW',
'aC',
'Ac',
'AC',
'AC1',
'AC2',
'ACA',
'ACARS',
'ACAS',
'ACB',
'ACC',
'ACCA',
'ACCAC',
'ACCD',
'ACCS',
'ACDA',
'ACDC',
'ACDT',
'ACE',
'ACES',
'ACF',
'ACHT',
'ACID',
'ACINT',
'ACK',
'ACL',
'ACLJ',
'ACLOS',
'ACLS',
'ACLU',
'ACM',
'ACMC',
'ACN',
'ACNA',
'ACO',
'ACOBA',
'ACOG',
'ACOM',
'ACORN',
'ACP',
'ACR',
'ACRI',
'ACRT',
'ACS',
'ACSC',
'ACST',
'ACST',
'ACT',
'ACTD',
'ACTF',
'ACTU',
'ACU',
'ACUF',
'ACV',
'AD',
'ADA',
'ADAC',
'ADAGE',
'ADAP',
'ADAS',
'ADAT',
'ADatP',
'ADATS',
'ADB',
'ADC',
'ADCTS',
'ADD',
'ADDIE',
'ADDNS',
'ADF',
'ADGB',
'ADHD',
'ADHS',
'ADI',
'ADIZ',
'ADL',
'ADM',
'ADOA',
'ADP',
'ADRG',
'ADS',
'ADSR',
'ADT',
'ADTB',
'ADW',
'ae',
'AE',
'AED',
'AEDT',
'AEF',
'AEGIS',
'AESR',
'AEST',
'aet',
'AEW',
'af',
'aF',
'AF',
'AFA',
'AFAIC',
'AFAICT',
'AFAIK',
'AFAIR',
'AFAM',
'AFATDS',
'AFC',
'AFCEA',
'AFDB',
'AFES',
'AFG',
'AFI',
'AFIS',
'AFIST',
'AFJ',
'AFK',
'AFL',
'AFLAC',
'AFM',
'AFN',
'AFO',
'AFOSR',
'AFP',
'AFQT',
'afr',
'AFRC',
'AFRL',
'AFROTC',
'AFRRI',
'AFRTS',
'AFS',
'AFSC',
'AFSCN',
'AFSPC',
'AFT',
'AFV',
'AFWA',
'ag',
'Ag',
'AG',
'AGA',
'AGARD',
'AGARDograph',
'AGASA',
'AGB',
'AGC',
'AGC',
'AGDC',
'AGI',
'AGL',
'AGM',
'AGN',
'AGO',
'AGP',
'AGRICOLA',
'AGS',
'AGTS',
'AGU',
'AGW',
'aH',
'Ah',
'AH',
'AHA',
'AHCPR',
'AHEC',
'AHPCRC',
'AHQ',
'AHRQ',
'AHS',
'AI',
'AIA',
'AIAA',
'AIC',
'AICD',
'AICMFP',
'AID',
'AIDS',
'AIM',
'AIMP',
'AIPAC',
'AIR',
'AIRFA',
'AIRS',
'AIS',
'AISB',
'aJ',
'AJ',
'Ajax',
'AJD',
'AJP',
'AJPW',
'ak',
'aK',
'AK',
'aka',
'AKA',
'AKDT',
'AKST',
'AKP',
'aL',
'Al',
'AL',
'ALA',
'ALAP',
'ALARM',
'ALB',
'ALD',
'ALERT',
'ALF',
'ALG',
'ALGOL',
'ALL',
'ALOA',
'ALOC',
'ALPA',
'ALS',
'ALSHS',
'ALSP',
'ALTE',
'ALTACS',
'am',
'Am',
'AM',
'AM1',
'AMA',
'AMAPS',
'AMC',
'AMCB',
'AMD',
'AMF',
'AMFOM',
'AMFR',
'AMFRS',
'amh',
'AML',
'AMLCD',
'AMLO',
'AMO',
'AMOLED',
'AMOS',
'AMP',
'AMRAAM',
'AMRDEC',
'AMS',
'AMSAA',
'AMSO',
'AMSU',
'AMTE',
'an',
'aN',
'AN',
'ANA',
'ANC',
'AND',
'ANFO',
'ANG',
'ANGLiCo',
'ANL',
'ANM',
'ANOVA',
'ANS',
'ANSI',
'ANTM',
'ANT',
'ANTY',
'ANWR',
'ANZ',
'ANZAC',
'AO',
'AOA',
'AOC',
'AOD',
'AOI',
'AOL',
'AOR',
'AOSTH',
'AOTS',
'AOU',
'AP',
'APA',
'APB',
'APC',
'APEC',
'APCNR',
'APCR',
'APDS',
'APEDS',
'APEX',
'APFSDS',
'APHA',
'APHE',
'APHIS',
'API',
'APL',
'APM',
'APOD',
'APOE',
'APOLLO',
'APP',
'APR',
'APS',
'AQ',
'AQIM',
'ar',
'Ar',
'AR',
'ara',
'ARAG',
'ARB',
'ARBA',
'ARC',
'ARCA',
'ARCENT',
'ARDA',
'ARE',
'ARF',
'arg',
'ARG',
'ARH',
'ARI',
'ARL',
'ARM',
'ARNet',
'ARP',
'ARPA',
'ARRC',
'ARRF',
'ARRL',
'ARS',
'ARSC',
'ARSPACE',
'ART',
'ARTCC',
'ARWG',
'as',
'aS',
'As',
'AS',
'AS',
'ASA',
'ASAP',
'ASARS',
'ASB',
'asbl',
'ASBO',
'ASCAP',
'ASCC',
'ASCIET',
'ASCII',
'ASD',
'ASDIC',
'ASEAN',
'ASFAR',
'ASG',
'ASIC',
'ASICS',
'ASIO',
'ASIP',
'ASL',
'asm',
'ASM',
'ASMB',
'ASME',
'ASMP',
'ASN',
'ASOC',
'ASP',
'ASPI',
'ASR',
'ASRAAM',
'ASSC',
'AST',
'ASTIA',
'ASTM',
'ASTREA',
'ASUW',
'ASVAB',
'ASVEL',
'ASW',
'ASWORG',
'ASX',
'aT',
'At',
'ATA',
'ATAC',
'ATACMS',
'ATAPI',
'ATAS',
'ATAT',
'ATB',
'ATC',
'ATCCIS',
'ATESS',
'ATF',
'ATG',
'ATGM',
'ATGW',
'ATI',
'Atisbo',
'ATK',
'ATL',
'ATLAS',
'ATM',
'ATN',
'ATO',
'ATP',
'ATSD',
'ATSDR',
'ATV',
'ATWA',
'Au',
'AU',
'AUC',
'AUD',
'AUS',
'AUSCANNZUKUS',
'Auslan',
'AUT',
'AUTL',
'av',
'aV',
'Av',
'AV',
'ava',
'AVA',
'AVB',
'AVDLR',
'ave',
'AVGP',
'AVI',
'AVIM',
'AVLB',
'AVM',
'AVO',
'AVP',
'AVP',
'AVRE',
'AVTB',
'AVUM',
'AVVO',
'aW',
'AW',
'AWA',
'AWACS',
'AWC',
'AWD',
'AWE',
'AWG',
'AWOL',
'AWP',
'AWSIM',
'AWST',
'AWW',
'AX',
'AXP',
'ay',
'AY',
'AYBABTU',
'aym',
'AYU',
'az',
'AZ',
'aze',
'AZE',
'AZN',
'AZUR',
'BAR',
'BB',
'BBB',
'BBC',
'BBD',
'BBL',
'BBS',
'BBYO',
'BC',
'BCA',
'BCCI',
'BCE',
'BCS',
'BEA',
'BES',
'BH',
'BHS',
'BNF',
'BNP',
'BO',
'BoAML',
'BOC',
'BOGs',
'BOI',
'BOM',
'BOQ',
'BOT',
'BP',
'BPV',
'BQ',
'BR',
'BRB',
'BRN',
'BS',
'BSA',
'BSatCoP',
'BSD',
'BSE',
'BSN',
'BSNL',
'BSP',
'BST',
'Bt',
'BT',
'BTN',
'C2C',
'Ca',
'CA',
'CAA',
'CAAT',
'CAC',
'CACM',
'CAD',
'CADCAM',
'CADPAT',
'CADMID',
'CAE',
'CAEn',
'CAF',
'CAFAD',
'CAFDE',
'CAI',
'CAIR',
'CALABARZON',
'CalPERS',
'Caltrans',
'CAM',
'CAMRA',
'CANA',
'CANDU',
'Canola',
'CAP',
'CAPES',
'CARE',
'CARMONETTE',
'CARP',
'CART',
'CAS',
'CASA',
'CASE',
'CASS',
'CASTFOREM',
'CASTOR',
'cat',
'CAT',
'CATK',
'CATS',
'CATT',
'CAV',
'CAVOK',
'CAW',
'CB',
'CBASSE',
'CBC',
'CBE',
'CBF',
'CBGB',
'CBLA',
'CBN',
'CBM',
'CBML',
'CBOL',
'CBOT',
'CBR',
'CBRN',
'CBRNE',
'CBS',
'CBT',
'CBUS',
'CC',
'CCA',
'CCC',
'CCCP',
'CCD',
'CCF',
'CCG',
'CCGC',
'CCGH',
'CCGS',
'CCH',
'CCIF',
'CCII',
'CCIR',
'CCIT',
'CCITT',
'CCK',
'CCM',
'CCOC',
'CCOP',
'CCP',
'CCPA',
'CCR',
'CCRAp',
'CCRP',
'CCRTS',
'CCSCS',
'CCSCS',
'CCSIL',
'CCSK',
'CCT',
'CCTT',
'cd',
'Cd',
'CD',
'CD1',
'CDA',
'CDC',
'CDD',
'CDE',
'CDEC',
'CDF',
'CDIAC',
'CDipAF',
'CDL',
'CDM',
'CDMA',
'CDMSII',
'Cdr',
'CDS',
'Cdt',
'CDT',
'ce',
'Ce',
'CE',
'CEA',
'CECOM',
'CEDEX',
'CEGEP',
'CEI',
'CENTCOM',
'CENTO',
'CENZUB',
'CEO',
'CEP',
'CEPT',
'CERCLA',
'CERDEC',
'CERGA',
'CERN',
'CERT',
'ces',
'CESS',
'CEV',
'CEWC',
'Cf',
'CF',
'CFA',
'CFAR',
'CFC',
'CFES',
'CFFZ',
'CFG',
'CFHT',
'CFI',
'CFL',
'CFLCC',
'CFM',
'CFO',
'CFP',
'CFR',
'CFRP',
'CFS',
'CFSP',
'CFU',
'CFV',
'CFZ',
'CG',
'CGDC',
'CGF',
'CGH',
'CGIG',
'CGM',
'CGO',
'CGS',
'CGT',
'ch',
'ch',
'CH',
'cha',
'CHAMPUS',
'CHAOS',
'CHAPS',
'CHDK',
'che',
'CHE',
'CHF',
'CHI',
'CHI',
'CHiP',
'CHIPS',
'CHL',
'CHLA',
'CHN',
'CHOGM',
'CHP',
'CHPSO',
'CHS',
'CHT',
'chu',
'chv',
'CREN',
'Ci',
'CI',
'CIA',
'CIAO',
'CIB',
'CIBC',
'CICS',
'CID',
'CID',
'CIEF',
'CIF',
'CIMIC',
'CIMMYT',
'CINC',
'CINEOS',
'CIO',
'CIP',
'CIPE',
'CIR',
'CIR',
'CIS',
'CISA',
'CISB',
'CISPR',
'CISSP',
'CITES',
'CITS',
'CIV',
'CIVETS',
'CIVPOP',
'CIWS',
'CHRS',
'CJ',
'CJC',
'CJD',
'CJCS',
'CJO',
'CJTF',
'CK',
'CKA',
'CKND',
'Cl',
'CL',
'CLA',
'CLEC',
'CLEMARS',
'CLI',
'CLIC',
'CLO',
'CLOB',
'CLOS',
'CLOS',
'CLP',
'CLPFC',
'CLRP',
'Cm',
'CM',
'CMA',
'CMB',
'CMBR',
'CME',
'CMHC',
'CMIIAW',
'CMIS',
'CMJ',
'CML',
'CMLL',
'CMM',
'CMMC',
'CMML',
'CMO',
'CMOC',
'CMOS',
'CMP',
'CMP',
'CMPD',
'CMPD',
'CMR',
'CMS',
'CMT',
'CMU',
'CMW',
'CMYK',
'CN',
'CNA',
'CNBC',
'CND',
'CNE',
'CNF',
'CNI',
'CNI',
'CNN',
'CNO',
'CNR',
'CNV',
'CNY',
'co',
'Co',
'CO',
'COA',
'COB',
'COBRA',
'COCOM',
'COD',
'CoDA',
'codec',
'COE',
'COEA',
'COEIA',
'COFT',
'COG',
'COGENT',
'COIL',
'COIN',
'COK',
'COL',
'COLA',
'COLT',
'COM',
'COMECON',
'COMMZ',
'COMSEC',
'CONAD',
'CONCACAF',
'CONMEBOL',
'CONOPS',
'CONUS',
'COO',
'COP',
'COPE',
'COPUS',
'cor',
'CORBA',
'CORD',
'CORE',
'CORG',
'COROT',
'cos',
'COS',
'COSCOM',
'COSER',
'CoT',
'COTS',
'Coy',
'CP',
'CPA',
'CPAC',
'CPCU',
'CPD',
'CPE',
'CPE',
'CPF',
'CPG',
'CPI',
'CPIM',
'CPIR',
'CPON',
'CPPCC',
'CPR',
'CPR',
'CPSM',
'CPSU',
'CPT',
'CPU',
'CPV',
'CPX',
'CQ',
'CQD',
'cr',
'Cr',
'CR',
'CRAM',
'CRC',
'CRD',
'cre',
'CREB',
'CREEP',
'CRI',
'CRIPL',
'CRM',
'CRO',
'CRP',
'CRT',
'CRTC',
'cs',
'Cs',
'CS',
'CSA',
'CSAR',
'CSC',
'CSEAL',
'CSERIAC',
'CSG',
'CSI',
'CSICOP',
'CSIP',
'CSIS',
'CSK',
'CSL',
'CSPI',
'CSR',
'CSR',
'CSRC',
'CSRF',
'CSS',
'CSSCS',
'CST',
'CT',
'CTA',
'CTBT',
'CTC',
'CTDB',
'CTE',
'CTGF',
'CTI',
'CTIL',
'CTIS',
'CTMS',
'CTO',
'CTS',
'CTSS',
'CTU',
'CTVT',
'cu',
'Cu',
'CU',
'CUB',
'CUCV',
'CUNY',
'CUP',
'CUPE',
'CUPS',
'CUPW',
'CUREA',
'CuW',
'CUW',
'cv',
'CV',
'CVCC',
'CVE',
'CVI',
'CVLL',
'CVR',
'CVS',
'CVSD',
'CVT',
'CW',
'CWC',
'CWS',
'CWRU',
'CX',
'CXR',
'cy',
'CY',
'cym',
'CYM',
'CYP',
'CZ',
'CZE',
'CZK',
'CZF',
'CZMA',
'd',
'D',
'da',
'DA',
'DAB',
'DACT',
'DADVSI',
'DAES',
'DAF',
'DAFIF',
'DAK',
'dan',
'DAO',
'DAQ',
'DARE',
'DARO',
'DARPA',
'DART',
'DAS',
'DASD',
'DASL',
'DAU',
'DAWN',
'dB',
'Db',
'DB',
'DBA',
'DBBL',
'DBCS',
'DBDO',
'DBE',
'DBF',
'DBM',
'DBR',
'DBRA',
'DBS',
'DC',
'DCA',
'DCAA',
'DCAS',
'DCC',
'DCC',
'DCCP',
'DCD',
'DCE',
'DCFC',
'DCI',
'DCIM',
'DCIMS',
'DCL',
'DCN',
'DCOM',
'DCOP',
'DCSOPS',
'DCW',
'DCYSC',
'DD',
'DDA',
'DDD',
'DDL',
'DDN',
'DDoS',
'DDP',
'DDR',
'DDS',
'DDT',
'de',
'DE',
'DEA',
'DEA',
'DEC',
'DeCA',
'DECSIM',
'DECT',
'DEFCON',
'DEFRA',
'DELT',
'DEM',
'DEN',
'DEPSECDEF',
'DERA',
'DES',
'deu',
'DEU',
'DF',
'DFAD',
'DFAS',
'DFB',
'DFCB',
'DfID',
'DFL',
'DFM',
'DFM',
'DFSP',
'DfT',
'DFTBA',
'DG',
'DGA',
'DGPS',
'DGZ',
'DH',
'DHA',
'DHHS',
'DHL',
'DHRA',
'DHS',
'DHSS',
'DHTFYSS',
'DHY',
'DIA',
'DIAC',
'DiC',
'DICASS',
'DILLIGAD',
'DILLIGAF',
'DIF',
'DIFAR',
'DIME',
'DIMM',
'DIN',
'DINA',
'DINKY',
'DIP',
'DIPS',
'DIR',
'DIS',
'DISA',
'DISCOM',
'DISE',
'DISSTAF',
'DITY',
'div',
'DIVAD',
'DIY',
'DJ',
'DJD',
'DJF',
'DJI',
'DJS',
'DK',
'DKK',
'DL',
'DLA',
'DLIC',
'DLIR',
'DLL',
'DLPMC',
'DLR',
'DLRP',
'DLS',
'DLSA',
'DLSc',
'DLSC',
'DLTBGYD',
'DM',
'DMA',
'DMC',
'DMCA',
'DMD',
'DMI',
'DMM',
'DMOC',
'DMOS',
'DMPA',
'DMPFC',
'DMPK',
'DMRB',
'DMS',
'DMSO',
'DMSP',
'DMT',
'DMU',
'DMV',
'DMZ',
'DN',
'DNA',
'DNAW',
'DND',
'DNDi',
'DNDO',
'DNM',
'DNK',
'DNR',
'DNS',
'DO',
'DOA',
'DOB',
'DOD',
'DODAF',
'DoDD',
'DoDEA',
'DOE',
'DOHC',
'DOI',
'DOM',
'DOMS',
'DOP',
'DORA',
'DOS',
'DOT',
'DOTA',
'DotD',
'DOTMLPF',
'DOW',
'DP',
'DP',
'DPA',
'DPCO',
'DPDT',
'DPICM',
'DPKO',
'DPMO',
'DPPDB',
'DPRE',
'DPRK',
'DPST',
'DQ',
'DR',
'DRA',
'DRAGN',
'DRAM',
'DRC',
'DRDC',
'DREA',
'DRES',
'DRET',
'DREV',
'DRI',
'DRIC',
'DRL',
'DRM',
'DRMS',
'DRPR',
'DRT',
'Ds',
'DS',
'DSA',
'DSAA',
'DSAS',
'DSB',
'DSC',
'DSCS',
'DSCSOC',
'DSD',
'DSDS',
'DSE',
'DSG',
'DSID',
'DSL',
'DSLR',
'DSM',
'DSN',
'DSO',
'dsp',
'dspl',
'dspm',
'dspml',
'dspms',
'dsps',
'DSP',
'DSPL',
'DSR',
'DSRV',
'DSS',
'DST',
'DSTL',
'dsvp',
'DSWA',
'DTA',
'DTD',
'DTE',
'DTED',
'DTG',
'DTH',
'DTI',
'DTIC',
'DTL',
'DTM',
'DTMF',
'DTLOMS',
'DTO',
'DTOMLS',
'DTR',
'DTRA',
'DTSA',
'DU',
'DUI',
'DUMBO',
'DUSD',
'dv',
'DV',
'DVD',
'DVLC',
'dvm',
'DVM',
'DVO',
'DVOM',
'DVP',
'dvp',
'dvu',
'DW',
'DWG',
'DWI',
'DWM',
'DWIM',
'DWTS',
'DWW',
'DX',
'DXA',
'DXM',
'Dy',
'DY',
'dz',
'DZ',
'DZA',
'DZD',
'dzo',
'E',
'EA',
'EABU',
'EAC',
'EAFUS',
'EAPC',
'EASA',
'EAST',
'EBCDIC',
'EBD',
'EBE',
'EBO',
'EBSA',
'EBW',
'EBW',
'EC',
'ECB',
'ECBR',
'ECASS',
'ECCM',
'ECFS',
'ECG',
'ECHO',
'ECHR',
'ECJ',
'ECL',
'ECM',
'ECommHR',
'ECS',
'ECSC',
'ECT',
'ECtHR',
'ECU',
'ECW',
'EDA',
'EDAC',
'EDCS',
'EDD',
'EDECSIM',
'EDGAR',
'EDH',
'EDS',
'EDSA',
'EDT',
'EDTA',
'ee',
'EE',
'EEC',
'EEG',
'EEK',
'EENT',
'EEO',
'EEOC',
'EEZ',
'EF',
'EFF',
'EFL',
'EFM',
'EFOA',
'EFSA',
'EFT',
'EFTA',
'EFV',
'EG',
'EGBUS',
'EGCG',
'EGIS',
'EGP',
'EGRET',
'EGY',
'EH',
'EHA',
'EHF',
'EHR',
'Ei',
'EI',
'EIA',
'EIAH',
'EIAH',
'EIC',
'EICS',
'eID',
'EIRP',
'EIS',
'EJ',
'EK',
'EKG',
'EKMS',
'el',
'EL',
'ELF',
'ELINT',
'ell',
'ELL',
'ELM',
'ELO',
'ELP',
'ELSS',
'ELT',
'Em',
'EM',
'EMA',
'Embraer',
'EMC',
'EMCON',
'EMD',
'EMDG',
'EMDR',
'EMEA',
'EMF',
'EMG',
'EMO',
'EMP',
'EMS',
'EMT',
'EMU',
'en',
'EN',
'ENCODE',
'ENE',
'eng',
'ENG',
'ENSATT',
'ENT',
'ENTEC',
'ENVL',
'ENY',
'eo',
'EO',
'EOD',
'EOG',
'EOS',
'EOSAT',
'EOSDIS',
'EOTS',
'EOM',
'EP',
'EP',
'EPA',
'EPCOT',
'EPCR',
'EPFL',
'EPGS',
'EPIRB',
'EPL',
'EPLRS',
'epo',
'EPO',
'EPOS',
'EPPA',
'EPR',
'EPW',
'EQ',
'EQG',
'Er',
'ER',
'ERA',
'ERCIM',
'ERI',
'ERLAWS',
'ERN',
'EROS',
'ERP',
'ERR',
'ERS',
'ERTS',
'ERV',
'es',
'Es',
'ES',
'ESA',
'ESC',
'ESD',
'ESDP',
'ESE',
'ESH',
'ESL',
'ESM',
'ESO',
'ESOL',
'ESP',
'ESPGHAN',
'ESPN',
'est',
'EST',
'ESU',
'ESV',
'et',
'ET',
'Exatesla',
'ETA',
'ETB',
'ETF',
'ETH',
'ETI',
'ETLA',
'ETM',
'ETOPS',
'ETT',
'eu',
'Eu',
'EU',
'EUCOM',
'EULA',
'EUR',
'eus',
'EUUSA',
'EV',
'EVA',
'EVIL',
'EW',
'ewe',
'EWR',
'EWSD',
'EWTN',
'EXFOR',
'EXREP',
'EYD',
'EZ',
'EZLN',
'f',
'F',
'fa',
'FA',
'fA',
'FA',
'FAA',
'FAAD',
'FAADEZ',
'FAAN',
'FAAFP',
'FAAN',
'FAAQ',
'FAASV',
'FAB',
'FAC',
'FACA',
'FACH',
'FACN',
'FACP',
'FACS',
'FACTS',
'FAD',
'FAH',
'FAI',
'FAM',
'fao',
'FAO',
'FAPA',
'FAPHA',
'FAQ',
'FARC',
'FARDC',
'FARP',
'fas',
'FAS',
'FASCAM',
'FASD',
'FASEB',
'FAT',
'FAVS',
'FAW',
'FB',
'FBCS',
'FBI',
'FBIS',
'FBIDST',
'FBM',
'FBR',
'FBS',
'fC',
'FC',
'FCA',
'FCC',
'FCE',
'FCF',
'FCLA',
'FCM',
'FCO',
'FCS',
'FCW',
'FCZ',
'FD',
'FDA',
'FDC',
'FDDI',
'FDBK',
'FDR',
'Fe',
'FE',
'FEAR',
'FEAST',
'FEATS',
'FEB',
'FEBA',
'FED',
'FEDEP',
'FEF',
'FEI',
'FEMA',
'FEMEXFUT',
'FENAFUTH',
'FENS',
'FEP',
'FEPAFUT',
'FES',
'FEST',
'FET',
'FEZ',
'ff',
'fF',
'FF',
'FFA',
'FFB',
'FFC',
'FFE',
'FFF',
'FFG',
'FFOV',
'FFR',
'FFS',
'FFT',
'FG',
'fg',
'FG',
'FGR',
'fH',
'FHFIF',
'FHI',
'fi',
'FI',
'FIA',
'FIAT',
'FIB',
'FIBA',
'FIBUA',
'FIC',
'FID',
'FIE',
'FIFA',
'FIFe',
'FIFO',
'FIFRA',
'FIG',
'FIGJAM',
'fij',
'FILA',
'FILO',
'FIMPT',
'fin',
'FIN',
'FINA',
'FIPS',
'FIR',
'FIRES',
'FIS',
'FISA',
'FISH',
'FiST',
'FITS',
'FIU',
'fj',
'fJ',
'FJ',
'FJD',
'FJI',
'FJN',
'fK',
'FK',
'FKP',
'FKSM',
'FKT',
'fl',
'fL',
'FL',
'FLA',
'FLAB',
'FLAC',
'FLAC',
'FLAK',
'FLB',
'FLET',
'FLG',
'FLIP',
'FLIR',
'FLK',
'FLOT',
'FLSA',
'FLQ',
'FLQ',
'FLTSAT',
'FLTSATCOM',
'FLUBOS',
'FM',
'fm',
'Fm',
'FM',
'FMC',
'FMCA',
'FMCAfam',
'FME',
'FMECA',
'FMJD',
'FMLA',
'Fmr',
'fMRI',
'FMS',
'FMSF',
'FMTV',
'FMCG',
'fN',
'FNA',
'fo',
'FO',
'FOAD',
'FOAF',
'FoB',
'FOB',
'FOC',
'FoE',
'FOGC',
'FOH',
'FOIA',
'FOM',
'FONSI',
'FOO',
'FOR',
'Fortran',
'FOS',
'FoS',
'FOSE',
'FOSE',
'FOT',
'FOTA',
'FOTC',
'FOUO',
'FOV',
'FP',
'FPF',
'fps',
'FPS',
'FOOP',
'FQ',
'FQDN',
'fr',
'Fr',
'FR',
'fra',
'FRA',
'FRAGO',
'FRAND',
'FRCP',
'FRICBM',
'frr',
'FRR',
'FRO',
'FRS',
'FRSA',
'fry',
'FRY',
'fs',
'fS',
'FS',
'FSA',
'FSB',
'FSC',
'FSCL',
'FSE',
'FSF',
'FSH',
'FSI',
'FSK',
'FSM',
'FSO',
'FSS',
'FSSP',
'FST',
'FSTC',
'FSV',
'fT',
'FTC',
'FTE',
'FTL',
'FTP',
'FTSE',
'FTW',
'FTS',
'FTS',
'FU',
'FUBAR',
'FUCK',
'FUD',
'ful',
'fV',
'fW',
'FW',
'FWC',
'FWF',
'FWI',
'FWIW',
'FWS',
'FWT',
'FX',
'FXX',
'fy',
'FY',
'FYA',
'FYDP',
'FYI',
'FYROM',
'FZDZ',
'FZFG',
'FZRA',
'g',
'G',
'G1',
'G2',
'G3',
'G4',
'G5',
'G6',
'G7',
'G8',
'G9',
'G10',
'G15',
'G20',
'G24',
'G77',
'Ga',
'GA',
'GAAR',
'GAB',
'GABA',
'GAD',
'GAF',
'GALCIT',
'GAO',
'GAW',
'GB',
'GBBC',
'GBE',
'GBH',
'GBP',
'GBR',
'GBU',
'GC',
'GCA',
'GCCS',
'GCD',
'GCF',
'GCI',
'GCN',
'GCS',
'GCSE',
'gd',
'Gd',
'GD',
'GDC',
'GDL',
'GDP',
'GDR',
'GDR',
'GDW',
'Ge',
'GE',
'GEBCO',
'GEC',
'GED',
'GEEP',
'GEL',
'GEMA',
'GEN',
'GEO',
'GEOS',
'GEQ',
'GER',
'Gestapo',
'GeV',
'GEV',
'GF',
'GFCF',
'GFCI',
'GFDL',
'GFE',
'GFI',
'GFL',
'GFN',
'GFY',
'GG',
'GGK',
'GGP',
'GH',
'GHA',
'GHB',
'GHC',
'Gi',
'GI',
'GIB',
'GIF',
'GIG',
'GIGO',
'GIMP',
'GIN',
'GINK',
'GINO',
'GIP',
'GIS',
'GISS',
'GJ',
'GJD',
'GK',
'gl',
'GL',
'gla',
'GLAAD',
'GLAC',
'GLAST',
'GLBA',
'GLC',
'gle',
'glg',
'GLINT',
'GLONASS',
'GLP',
'GLUT',
'glv',
'GLV',
'Gm',
'GM',
'GMAC',
'GMB',
'GmbH',
'GMC',
'GMD',
'GMFSC',
'GMI',
'GML',
'GMO',
'GMPCS',
'GMT',
'gn',
'GN',
'GNAS',
'GNB',
'GNC',
'GNF',
'GNOME',
'GNP',
'GNQ',
'GnRH',
'GNU',
'GO',
'GOA',
'GOAT',
'GOC',
'GOES',
'GOP',
'gorp',
'GOSC',
'GOSH',
'GOSP',
'GOTS',
'GOYADIN',
'GP',
'GPA',
'GPC',
'GPG',
'GPIB',
'GPL',
'GPMP',
'GPO',
'GPPC',
'GPR',
'GPS',
'GPT',
'GPU',
'GQ',
'GR',
'GRAIL',
'GRAS',
'GRB',
'GRC',
'GRD',
'GRE',
'grep',
'GRid',
'GRID',
'GriT',
'GRL',
'GRN',
'grn',
'GRP',
'GRU',
'Gs',
'GS',
'GSR',
'GSA',
'GSD',
'GSG',
'GSGB',
'GSK',
'GSM',
'GSN',
'GSO',
'GSOH',
'GSP',
'GSS',
'GST',
'GSW',
'GT',
'GTA',
'GTB',
'GTC',
'GTD',
'GTG',
'GTK',
'GTGN',
'GTM',
'GTQ',
'GTV',
'gu',
'GU',
'GUA',
'GUF',
'GUI',
'GUILT',
'guj',
'GULCR',
'Gulag',
'GUM',
'GUT',
'gv',
'GV',
'GW',
'GWARS',
'GWAS',
'GWOT',
'GWS',
'GXA',
'GXE',
'GXF',
'GXH',
'GXL',
'GXM',
'GXP',
'GxP',
'GXY',
'GY',
'GYD',
'GYY',
'GZ',
'GZK',
'h',
'H',
'ha',
'HA',
'HAARP',
'HAART',
'HAI',
'HAL',
'HALO',
'HALT',
'HAM',
'HANS',
'HARM',
'hat',
'HAT',
'hau',
'HAW',
'HAWK',
'HAZ',
'HAZMAT',
'HBCD',
'HBCI',
'HBO',
'HBR',
'HBT',
'HBV',
'HCCI',
'HCF',
'HCI',
'HCSC',
'HCT',
'HD',
'HDD',
'HDL',
'HDM',
'HDMI',
'HDR',
'HDRA',
'HDTV',
'he',
'He',
'HE',
'HEAT',
'heb',
'HEBA',
'HEER',
'HEIC',
'HEMP',
'HEO',
'HEOSS',
'HEP',
'HEPA',
'her',
'HESH',
'HESV',
'HETT',
'HEU',
'Hewitts',
'Hf',
'HF',
'HFAC',
'HFE',
'HFE',
'HFEA',
'Hg',
'HGC',
'HGF',
'HGTV',
'HHD',
'HHB',
'hi',
'HI',
'HIDACZ',
'HIH',
'HIM',
'HIMAD',
'HIMEZ',
'HIMYM',
'hin',
'HIPAA',
'Hipparcos',
'Hird',
'HiRISE',
'HIT',
'HIV',
'HIWT',
'HJ',
'HJD',
'HK',
'HKD',
'HKG',
'HKS',
'hL',
'HL',
'HLA',
'HLL',
'HLN',
'HLOS',
'HLS',
'HLVW',
'HM',
'HMCE',
'HMCS',
'HMD',
'HMF',
'HMM',
'HMMWV',
'hmo',
'HMO',
'HMP',
'HMRC',
'HMS',
'HMSO',
'HMV',
'HN',
'HND',
'HNL',
'ho',
'Ho',
'HO',
'HOLLAND',
'HOMES',
'HONY',
'HOPI',
'HoReCa',
'HORIZON',
'HOSTAC',
'HOT',
'HOTAS',
'HOV',
'hp',
'HP',
'HPA',
'HPCL',
'HPLC',
'HPT',
'HPV',
'HQ',
'HQDA',
'hr',
'HR',
'HRBL',
'HRD',
'HRED',
'HRF',
'HRH',
'HRK',
'HRM',
'HRM',
'HRT',
'hrv',
'HRV',
'Hs',
'HSBC',
'HSC',
'HSM',
'HSMAD',
'HSN',
'HSPH',
'HSS',
'HSSI',
'HST',
'HSV',
'ht',
'HT',
'HTA',
'HTC',
'HTFU',
'HTG',
'HTH',
'HTI',
'HTML',
'HTR',
'HTS',
'HTTP',
'HU',
'HUD',
'HUF',
'HUHA',
'HUMINT',
'hun',
'HUN',
'HURD',
'HVU',
'HV',
'HVAC',
'HVAP',
'HVHF',
'HVM',
'HVO',
'HVP',
'HW',
'HWM',
'HX',
'HXB',
'hy',
'hye',
'HYS',
'hz',
'Hz',
'HZ',
'I',
'ia',
'IA',
'IAAF',
'IAAL',
'IAB',
'IACREOT',
'IAD',
'IADS',
'IAEA',
'IAF',
'IAFG',
'IAI',
'IANA',
'IANAL',
'IAQ',
'IAS',
'IASIP',
'IATA',
'IATF',
'IATSE',
'IAU',
'IAUC',
'IAW',
'IB',
'IBA',
'IBD',
'IBDM',
'IBDS',
'IBM',
'ibo',
'IBRD',
'IBS',
'IC',
'ICANN',
'ICAO',
'ICBM',
'ICBM',
'ICBN',
'ICC',
'ICD',
'ICE',
'ICES',
'ICF',
'ICHTHYS',
'ICM',
'ICMP',
'ICNAF',
'ICNI',
'ICO',
'ICP',
'ICPE',
'ICRAF',
'ICRISAT',
'ICRL',
'ICRS',
'ICS',
'ICSI',
'ICT',
'ICTH',
'ICV',
'ICW',
'ICYMI',
'ICZN',
'id',
'ID',
'IDA',
'IDC',
'IDDI',
'IDE',
'IDeA',
'IDEA',
'IDF',
'IDGAF',
'IDK',
'IDKIBTI',
'IDL',
'IDN',
'ido',
'IDR',
'ie',
'IE',
'IEA',
'IEC',
'IED',
'IEEE',
'IELTS',
'IER',
'IET',
'IET',
'IETF',
'IEW',
'IFAB',
'IFAH',
'IFAP',
'IFC',
'IFF',
'IFFN',
'IFO',
'IFOR',
'IFOV',
'IFPI',
'IFR',
'IFRB',
'IFV',
'ig',
'IG',
'IGNOU',
'IGS',
'IGY',
'IHH',
'IHO',
'IHOP',
'IHR',
'IHSI',
'ii',
'II',
'IICA',
'IIE',
'iii',
'III',
'IIRC',
'IISS',
'IIT',
'IJN',
'IJWP',
'ik',
'IKEA',
'iku',
'IL',
'ILC',
'ILDS',
'ile',
'ILO',
'ILRI',
'ILS',
'ILY',
'IM',
'IMA',
'IMBD',
'IMDb',
'IME',
'IMF',
'IMHO',
'IML',
'IMLAST',
'IMNM',
'IMNSHO',
'IMPAC',
'IMPACT',
'IMPATT',
'IMPT',
'IMR',
'IMRI',
'IMRO',
'IMRL',
'IMS',
'IMSA',
'IMsL',
'IMT',
'IMU',
'In',
'ina',
'INA',
'INAS',
'INC',
'INCITS',
'ind',
'IND',
'inet',
'INDIGO',
'INFORMS',
'INFOSEC',
'INLA',
'INMARSAT',
'INR',
'INRI',
'INRIA',
'INS',
'InSAR',
'INST',
'INTSUM',
'INTERFET',
'io',
'IO',
'IOC',
'IOLTA',
'IOM',
'IONA',
'IOT',
'IOU',
'IP',
'IPA',
'IPB',
'IPCC',
'ipk',
'IPL',
'IPN',
'IPO',
'IPR',
'IPT',
'IPTS',
'IPTV',
'IPW',
'iq',
'IQ',
'IQD',
'Ir',
'IR',
'IRA',
'IRAD',
'IRAN',
'IRAS',
'IRB',
'IRBM',
'IRC',
'IREA',
'IRL',
'IRM',
'IRN',
'IRINN',
'IRO',
'IRQ',
'IRR',
'IRS',
'IRSN',
'IRST',
'is',
'ISA',
'ISAAA',
'ISAF',
'ISAR',
'ISB',
'ISBN',
'ISCCP',
'ISCII',
'ISDN',
'ISEF',
'ISEN',
'ISF',
'ISI',
'ISIL',
'ISIS',
'ISK',
'isl',
'ISL',
'ISLN',
'ISM',
'ISMB',
'ISMC',
'ISO',
'ISOGP',
'ISP',
'ISPE',
'ISR',
'ISRO',
'ISS',
'ISSCR',
'ISSN',
'IST',
'ISTAR',
'ISTC',
'ISTS',
'ISU',
'it',
'IT',
'ita',
'ITA',
'ITAG',
'ITALY',
'ITAS',
'ITC',
'ITCZ',
'ITEC',
'ITEMS',
'ITER',
'ITF',
'ITK',
'ITN',
'ITS',
'ITT',
'ITTO',
'ITU',
'iu',
'IUCN',
'IUPAC',
'IUSS',
'IV',
'IVD',
'Iveco',
'IVF',
'IVI',
'IVIS',
'IVL',
'IVM',
'IVO',
'IW',
'IWARS',
'IWC',
'IWW',
'IX',
'IXT',
'IY',
'IZ',
'J',
'J1',
'J2',
'J2EE',
'J2ME',
'J2SE',
'J3',
'J4',
'J5',
'J6',
'J7',
'J8',
'J9',
'ja',
'JA',
'JAFT',
'JAG',
'JAL',
'JALLC',
'JAM',
'JAMA',
'JANET',
'JANUS',
'JATO',
'jav',
'JB',
'JB',
'JBFSA',
'JCATS',
'JCDB',
'JCDS',
'JCIDS',
'JCIET',
'JCL',
'JCMOTF',
'JCP',
'JCPM',
'JCS',
'JCW',
'JD',
'JDAM',
'JDBC',
'JDCC',
'JDEM',
'JDK',
'JDL',
'JDM',
'JE',
'JEB',
'JEDP',
'JEE',
'JEFX',
'JEPD',
'JET',
'JEZ',
'JFACC',
'JFACTSU',
'JFC',
'JFCOM',
'JFET',
'JFK',
'JFLCC',
'JFMCC',
'JFO',
'JFSOCC',
'JFTX',
'JH',
'JH',
'JI',
'JIB',
'JIC',
'JINI',
'JITC',
'JIVE',
'JJAP',
'JK',
'JL',
'JLA',
'JM',
'JMD',
'JMS',
'JN',
'JNI',
'JNLP',
'JO',
'JOA',
'JOD',
'Jofa',
'JOPES',
'JOR',
'JOTS',
'JSP',
'JP',
'JPEG',
'JPL',
'jpn',
'JPN',
'JPOW',
'JPY',
'JQ',
'JRA',
'JRDF',
'JRE',
'JREF',
'JROC',
'JROCM',
'JRRF',
'JRSAI',
'JSAF',
'JSAP',
'JSCSC',
'JSD',
'JSEAD',
'JSF',
'JSIMS',
'JSOA',
'JSOTF',
'JSP',
'JSR',
'JSS',
'JSTARS',
'JT',
'JTAG',
'JTAGS',
'JTC',
'JTF',
'JTFEX',
'JTIDS',
'JTLS',
'JTMD',
'JTN',
'JTRS',
'JU',
'JUGFET',
'JUICE',
'JUO',
'jv',
'JV',
'JVM',
'JW',
'JWARS',
'JWP',
'JWST',
'JY',
'JZ',
'k',
'K',
'K9',
'ka',
'kA',
'Ka',
'KA',
'KAF',
'kal',
'kan',
'KANUKOKA',
'KARNAC',
'KARR',
'kas',
'kat',
'KATUSA',
'kau',
'kaz',
'KAZ',
'KBE',
'KBO',
'KBVB',
'kC',
'KCK',
'KCL',
'KCMO',
'KCS',
'KD',
'KE',
'KEDO',
'KEN',
'KERA',
'kerma',
'KES',
'KESR',
'KET',
'kF',
'KFC',
'KFOR',
'KFUPM',
'kg',
'KG',
'KGB',
'KGO',
'KGS',
'KGZ',
'kH',
'KH',
'khm',
'KHL',
'KHM',
'KHR',
'KHz',
'ki',
'Ki',
'KI',
'KIA',
'KIDS',
'kik',
'kin',
'kir',
'KIR',
'KISS',
'KITECH',
'KITT',
'KIT',
'kj',
'kJ',
'kk',
'kK',
'KK',
'KKK',
'kl',
'kL',
'KL',
'KLENOT',
'KLM',
'KLOC',
'KLS',
'km',
'KM',
'KMF',
'KMFDM',
'KMR',
'kn',
'kN',
'KN',
'KNA',
'KNVB',
'ko',
'KO',
'kom',
'kon',
'kor',
'KOR',
'KP',
'KPMG',
'KPNO',
'KPV',
'KPVT',
'KPW',
'KQ',
'kr',
'Kr',
'KR',
'KRW',
'ks',
'kS',
'KS',
'KSC',
'KSI',
'KSM',
'KSU',
'kT',
'KT',
'KTS',
'ku',
'KU',
'kua',
'KUL',
'kur',
'KUW',
'kv',
'kV',
'KV',
'KV',
'KVM',
'KVT',
'kw',
'kW',
'KW',
'KWB',
'KWD',
'KWT',
'KX',
'ky',
'KY',
'KYD',
'KZ',
'KZN',
'KZT',
'L',
'la',
'La',
'LA',
'LAAS',
'LAB',
'LabVIEW',
'LACMA',
'LAD',
'LADW',
'LAESI',
'LAK',
'LAMP',
'LAN',
'LANL',
'lao',
'LAO',
'LAPD',
'LAR',
'LARC',
'LARP',
'LAS',
'Laser',
'lat',
'LAT',
'LATA',
'LATCH',
'lav',
'LAV',
'LAW',
'LAX',
'lb',
'LB',
'LBH',
'LBJ',
'LBN',
'LBNL',
'LBP',
'LBR',
'LBS',
'lbs',
'LBT',
'LBW',
'LBY',
'LC',
'LCA',
'LCC',
'LCCP',
'LCD',
'LCE',
'LCM',
'LCol',
'LCS',
'LD',
'LDA',
'LDAP',
'LDC',
'LDL',
'LDMOS',
'LDS',
'LDV',
'LDW',
'LE',
'LEC',
'LED',
'Legs',
'LEGAD',
'LEMOSS',
'LEN',
'LEO',
'LEP',
'LER',
'LEU',
'LF',
'LFO',
'LFTR',
'lg',
'LG',
'LGB',
'LGBT',
'LGLO',
'LGM',
'LGS',
'LH',
'LHC',
'LHD',
'LHS',
'li',
'Li',
'LI',
'LIBOR',
'LIBS',
'LIE',
'LIFO',
'LIGO',
'lim',
'lin',
'LINEAR',
'LISA',
'lit',
'LITAS',
'LIVE',
'LJ',
'LJJ',
'LK',
'LKA',
'LKL',
'LKR',
'LKR4',
'LL',
'LLAD',
'LLB',
'LLC',
'LLGC',
'LLLTV',
'LLNL',
'LLP',
'LLTI',
'LLTR',
'LMAO',
'LMFAO',
'LMB',
'LMN',
'LMS',
'LMS',
'LMV',
'ln',
'LNB',
'LNER',
'LNG',
'LNO',
'LNR',
'lo',
'LO',
'LOA',
'LOC',
'LOD',
'LoD',
'LOFAR',
'LOGPAC',
'LOL',
'LOMEZ',
'LONEOS',
'LOOGY',
'loq',
'LORTID',
'LOS',
'LOSAT',
'LOTA',
'LOTIS',
'LOTS',
'LP',
'LPC',
'LPD',
'LPG',
'LPI',
'LPS',
'LQ',
'Lr',
'LR',
'LRAD',
'LRC',
'LRD',
'LRF',
'LRGW',
'LRICBM',
'LRIP',
'LRP',
'LRPE',
'LRS',
'LRU',
'LS',
'LSAT',
'LSD',
'LSE',
'LSI',
'LSIF',
'LSL',
'LSND',
'LSO',
'LSST',
'LST',
'LSVW',
'lt',
'Lt',
'LtC',
'LT',
'LTC',
'Ltd',
'LTDP',
'LTG',
'LTI',
'LTM',
'LTL',
'LTP',
'LTU',
'ltz',
'lu',
'Lu',
'LU',
'LU',
'lub',
'LUCA',
'lug',
'LUG',
'LULU',
'LUT',
'LUVW',
'LUX',
'lv',
'LV',
'LVA',
'LVAD',
'LVEF',
'LVL',
'Lw',
'LW',
'LWR',
'LWRC',
'LWS',
'LX',
'LX',
'LY',
'LYAO',
'LYD',
'LYLAS',
'LZ',
'm',
'M',
'mA',
'Ma',
'MA',
'MAA',
'MAAC',
'MAC',
'MACA',
'MACE',
'MACHO',
'MAD',
'MADD',
'MAF',
'MAFF',
'MAGIC',
'MAGNETAR',
'MAGTF',
'mah',
'Mahasz',
'mal',
'MALD',
'MANPADS',
'MANPRINT',
'MAO',
'MAOI',
'MAPS',
'MAQS',
'mar',
'MAR',
'MarBEF',
'MARC',
'MARCENT',
'MARFORCC',
'MARRS',
'MAS',
'MASAS',
'Maser',
'MASH',
'MASINT',
'MATREX',
'MATS',
'MAVS',
'MAW',
'MAX',
'MB',
'MBA',
'MBI',
'MBBL',
'MBCC',
'MBCS',
'MBE',
'MBIDST',
'MBM',
'mbsf',
'MBT',
'MBTA',
'MBTI',
'mC',
'MC',
'MCA',
'MCC',
'MCD',
'MCDL',
'MCFT',
'MCG',
'MCI',
'MCIS',
'MCM',
'MCMV',
'MCNC',
'MCO',
'MCP',
'MCR',
'MCSF',
'MCT',
'MCTIS',
'MCU',
'Md',
'MD',
'MDA',
'MDF',
'MDG',
'MDL',
'MDM',
'MDMA',
'MDNA',
'MDO',
'MDP',
'MDT',
'MDV',
'MDW',
'Me',
'ME',
'MEA',
'MEAC',
'MEC',
'MECC',
'MECCA',
'MECO',
'MEDEVAC',
'MEDLI',
'MEDS',
'MEF',
'MEG',
'MEI',
'MEK',
'MEL',
'MELT',
'MEM',
'MEMS',
'MEO',
'MEP',
'MER',
'MERL',
'MES',
'MESSENGER',
'MET',
'METAR',
'METL',
'METOC',
'MEU',
'MeV',
'MEV',
'MEX',
'MEZ',
'MES',
'mF',
'MF',
'MFSB',
'mg',
'Mg',
'MG',
'MGA',
'MGB',
'MGM',
'MGPAM',
'MGS',
'mh',
'mH',
'MH',
'MHC',
'MHHW',
'MHL',
'MHLW',
'MHRA',
'MHW',
'MHWN',
'MHWS',
'MHz',
'mi',
'Mi',
'MI',
'MIA',
'MIAA',
'MIB',
'MIC',
'MICLIC',
'MICOM',
'MICV',
'MID',
'MIDI',
'MIDAS',
'MIDS',
'MIFC',
'MIFG',
'MIFPC',
'MIKE',
'MIL',
'MILAN',
'MILC',
'MILES',
'MILF',
'MILSATCOM',
'MILSET',
'MILSTRIP',
'MIMAROPA',
'MIMOSA',
'MIMPT',
'MIP',
'MIPC',
'MIRA',
'MIRV',
'MIS',
'MISREP',
'MISSI',
'MIT',
'MITL',
'MITS',
'mJ',
'MJ',
'MJD',
'MJP',
'mk',
'mK',
'MK',
'mkd',
'MKD',
'MKG',
'MKO',
'MKS',
'MKSA',
'MKT',
'ml',
'mL',
'ML',
'MLA',
'MLB',
'MLC',
'MLE',
'mlg',
'MLHW',
'MLI',
'MLK',
'MLL',
'MLLW',
'MLP',
'MLRS',
'MLS',
'MLSE',
'MLSR',
'mlt',
'MLT',
'MLVW',
'MLW',
'MLWN',
'MLWS',
'mm',
'Mm',
'MM',
'MMA',
'MMC',
'MMCM',
'MMCS',
'MMEV',
'MMI',
'MMI',
'MMIC',
'MMIC',
'MMI',
'MMK',
'MMO',
'MMOR',
'MMORPG',
'MMPR',
'MMR',
'MMS',
'MMU',
'MMW',
'mn',
'mN',
'Mn',
'MN',
'MNB',
'MND',
'MNE',
'MNG',
'MNMF',
'MNP',
'MNS',
'MNT',
'mo',
'Mo',
'MO',
'MOA',
'MOAB',
'MOB',
'MOC',
'MOCAS',
'MoD',
'MOD',
'MoDAF',
'modem',
'ModSAF',
'Moe',
'MOE',
'MOF',
'MOFA',
'MOH',
'MOID',
'MOJWA',
'mol',
'MOLO',
'MoM',
'MOM',
'MOM',
'MoMA',
'mon',
'MOND',
'MOO',
'MOOTW',
'MOP',
'MOPE',
'MOPP',
'MORP',
'MORS',
'MOS',
'MOSFET',
'MOSS',
'MOT',
'MOTA',
'MOTESS',
'MOTO',
'MOU',
'MOUT',
'MOVES',
'MOW',
'MOZ',
'MP',
'mPa',
'MPa',
'MPA',
'MPAA',
'MPC',
'MPC',
'MPEC',
'MPEG',
'MPEV',
'MPF',
'MPFC',
'MPG',
'MPMC',
'MPSI',
'MPSoC',
'MPSRON',
'MQ',
'mr',
'MR',
'MRAV',
'MRBM',
'MRC',
'MRD',
'MRE',
'MRGO',
'MRGW',
'mri',
'MRI',
'MRL',
'MRO',
'MRPharmS',
'MRR',
'MRS',
'MRSA',
'MRT',
'MRTFB',
'ms',
'mS',
'Ms',
'MS',
'msa',
'MSB',
'MSD',
'MSDB',
'MSDE',
'MSDL',
'MSE',
'MSEC',
'MSG',
'MSH',
'MSI',
'MSIE',
'MSL',
'MSLP',
'MSM',
'MSN',
'MSNBC',
'MSO',
'MSPA',
'MSPB',
'MSR',
'MSSA',
'MST',
'MSU',
'mt',
'mT',
'Mt',
'MT',
'MTA',
'MTBF',
'MtF',
'MTF',
'MTG',
'MTL',
'MTOE',
'MTP',
'MTQ',
'MTR',
'MTS',
'MTT',
'MTU',
'MTV',
'MTVL',
'MTWS',
'MU',
'MUA',
'MUR',
'MUS',
'mV',
'MV',
'MVC',
'MVFC',
'MVNO',
'MVR',
'mW',
'MW',
'MWC',
'MWESH',
'MWI',
'MWK',
'MWOA',
'MWR',
'MWTB',
'MX',
'MXN',
'my',
'MY',
'mya',
'MYOB',
'MYR',
'MYS',
'MYT',
'MZ',
'MZN',
'n',
'N',
'N3F',
'na',
'nA',
'Na',
'NA',
'NAA',
'NAAD',
'NAAFI',
'NAAK',
'NAACP',
'NAAQS',
'NAAWS',
'NABCI',
'NAC',
'NACA',
'NACC',
'NACISA',
'NACMA',
'NACRA',
'NAD',
'NAEC',
'NAF',
'NAFLD',
'NAFO',
'NAFERA',
'NAFTA',
'NAGPRA',
'NAI',
'NAICS',
'NAIL',
'NAILS',
'NAIRU',
'NAK',
'NAM',
'NAMBLA',
'NAMRL',
'NaN',
'NAO',
'NAPDD',
'NAPS',
'NARA',
'NARAL',
'NARDAC',
'NARDIC',
'NARPV',
'NAS',
'NASA',
'NASCAR',
'NASDAQ',
'NASED',
'NASH',
'NASP',
'NASS',
'NatGeo',
'NATO',
'nau',
'NaUKMA',
'nav',
'NAV',
'NAVCAMS',
'NAVCC',
'NAVCENT',
'NAVDAC',
'NAVEUR',
'NAVFE',
'NAVFOR',
'NAVMACS',
'NAVNET',
'NAVS',
'NAVSAT',
'NAVSPACECOM',
'NAVSPASUR',
'NAVSTAR',
'NAVTACNET',
'NAWC',
'NAWMP',
'nb',
'Nb',
'NB',
'NBA',
'NBACC',
'NBC',
'NBCRS',
'NBIC',
'nbl',
'NBL',
'NBT',
'nC',
'NC',
'NCA',
'NCAA',
'NCAR',
'NCB',
'NCCAM',
'NCCUSL',
'NCDDR',
'NCES',
'NCI',
'NCIS',
'NCL',
'NCLR',
'NCMO',
'NCND',
'NCO',
'NCP',
'NCR',
'NCTR',
'NCW',
'nd',
'Nd',
'ND',
'NDAA',
'NDBP',
'NDCC',
'nde',
'NDH',
'ndo',
'NDP',
'NDS',
'NDU',
'ne',
'Ne',
'NE',
'NEA',
'NEAT',
'NEC',
'NED',
'NECTEC',
'NEI',
'NEMS',
'NEO',
'nep',
'NEPA',
'NER',
'NERICA',
'NESDIS',
'NESP',
'NET',
'NETCU',
'NETL',
'NETWARS',
'NEWS',
'NEX',
'nF',
'NF',
'NFA',
'NFC',
'NFER',
'NFG',
'NFHS',
'NFI',
'NFK',
'NFL',
'Nfld',
'NFPA',
'NFT',
'ng',
'NG',
'NGA',
'NGH',
'NGATS',
'NGIC',
'NGN',
'NGO',
'NGR',
'NGS',
'nH',
'NH',
'NHANES',
'NHB',
'NHB',
'NHL',
'NHO',
'NHRA',
'NHS',
'Ni',
'NI',
'NIAAA',
'NIAID',
'NIAG',
'NIAMD',
'NIB',
'NIBP',
'NIC',
'NicA',
'NICAP',
'NICE',
'NICHD',
'NICP',
'NIDDK',
'NIDRR',
'NIEHS',
'NIG',
'NIH',
'NIIN',
'NIMA',
'NIMBY',
'NIMG',
'NiMH',
'NIMH',
'NIN',
'NIO',
'NIPR',
'NIR',
'NISAC',
'NISCAP',
'NISER',
'NIST',
'NIT',
'NITFS',
'NIU',
'nJ',
'NJ',
'NJHS',
'NJPW',
'nK',
'NKA',
'NKVD',
'nl',
'nL',
'NL',
'NLB',
'nld',
'NLD',
'NLF',
'NLL',
'NLP',
'NLW',
'nm',
'NM',
'NMCI',
'NMCS',
'NMD',
'NMN',
'NMOS',
'NMSG',
'NMR',
'NMHNFG',
'nn',
'nN',
'NNE',
'nno',
'NNSA',
'NNW',
'no',
'No',
'NO',
'NOAA',
'NOAF',
'NOAO',
'nob',
'NOC',
'NODEF',
'NOK',
'NOLA',
'NOGI',
'NOLF',
'NOM',
'NOME',
'NoMBO',
'NOME',
'NOPA',
'nor',
'NOR',
'NORAD',
'NORCECA',
'NORELPREF',
'NORML',
'NORTHAG',
'NOS',
'NOSA',
'NOTAFLOF',
'NOTAM',
'NOW',
'Np',
'NP',
'NP',
'NPB',
'NPC',
'NPCS',
'NPD',
'NPDI',
'NPG',
'NPH',
'NPL',
'NPOV',
'NPR',
'NQ',
'NQOCD',
'NPO',
'nr',
'NR',
'NRA',
'NRAO',
'NRC',
'NRDC',
'NREL',
'NRK',
'NRMP',
'NRO',
'NRT',
'NRTA',
'NRU',
'ns',
'nS',
'NS',
'NSA',
'NSABB',
'NSAID',
'NSB',
'NSC',
'NSDAP',
'NSDL',
'NSG',
'NSN',
'NSNA',
'NSO',
'NSS',
'NST',
'NSTAC',
'NSV',
'NSW',
'nT',
'NT',
'NTC',
'NTDS',
'NTP',
'NTR',
'NTRA',
'NTSB',
'NTSC',
'NTV',
'NTY',
'NTZ',
'NU',
'NUI',
'NUL',
'NUTS',
'NUWC',
'nv',
'nV',
'NV',
'NVEOL',
'NVESD',
'NVG',
'NVL',
'nW',
'NW',
'NW',
'NWA',
'NWF',
'NWFZ',
'NWHN',
'nWo',
'NWOAHM',
'NWOBHM',
'NWRA',
'NWSL',
'NWT',
'NX',
'NXA',
'NXD',
'NXE',
'NXG',
'NXL',
'NXN',
'NXP',
'ny',
'NY',
'nya',
'NYHA',
'NYO',
'NYRA',
'NYSE',
'NZ',
'NZC',
'NZD',
'NZL',
'NzL',
'NZOSS',
'NZRU',
'O',
'OA',
'OAC',
'OAP',
'OAS',
'OARSI',
'OASIS',
'OASIS',
'OASIS',
'OASP',
'OAU',
'ob',
'OB',
'OBE',
'OBJ',
'OBS',
'OBSS',
'OBX',
'oc',
'OC',
'OCA',
'OCD',
'OCFS',
'OCFS2',
'oci',
'OCIDS',
'OCMCC',
'OCN',
'OCO',
'OCOKA',
'OCR',
'OCS',
'OD',
'ODAS',
'ODB',
'ODBC',
'ODI',
'OEA',
'OED',
'OECD',
'OEG',
'OEIS',
'OEM',
'OFA',
'OFDA',
'OGC',
'OGD',
'OGO',
'OGX',
'OH',
'OHC',
'OHHLA',
'OHIP',
'OHMS',
'OHP',
'OHSS',
'OIC',
'OIE',
'OIF',
'OIOC',
'oj',
'OJ',
'oji',
'OJT',
'OK',
'OKC',
'OLED',
'OLS',
'om',
'OM',
'OMA',
'OMB',
'OMD',
'OME',
'OMFG',
'OMG',
'OMGW',
'OMN',
'OMNCS',
'OMP',
'OMR',
'OMS',
'OMT',
'OMW',
'ON',
'Onna',
'ONC',
'ONCE',
'ONG',
'ONH',
'ONI',
'ONS',
'OO',
'OOO',
'OOAD',
'OOBE',
'OOCL',
'OODA',
'OOMF',
'OOP',
'OOS',
'OOTW',
'OP',
'OPA',
'OPAC',
'OPCON',
'OPCW',
'OPEC',
'OPFOR',
'OPI',
'OPINTEL',
'OPLAN',
'OPORD',
'OPP',
'OPS',
'OPSEC',
'OPSI',
'OPTAR',
'OPV',
'OQ',
'or',
'OR',
'ORAU',
'ORB',
'ORBAT',
'ORD',
'ORD',
'ORF',
'ori',
'ORISE',
'orm',
'ORNL',
'ORR',
'ORSA',
'OS',
'os',
'Os',
'OSA',
'OSCE',
'OSD',
'OSDL',
'OSE',
'OSERS',
'OSETI',
'OSHA',
'OSI',
'OSIA',
'OSL',
'OSIRIS',
'OSM',
'OSOTPBOIAS',
'OSPA',
'OSPA',
'OSRH',
'oss',
'OSS',
'OSU',
'OTA',
'OTAC',
'OTB',
'OTC',
'OTD',
'OTEA',
'OTI',
'OTO',
'OTOH',
'OTS',
'OTSBH',
'OTW',
'OTYBL',
'OTYRL',
'OU',
'OVC',
'OVV',
'OW',
'OWN',
'OX',
'OXC',
'OXF',
'OXM',
'OXR',
'OXT',
'Oy',
'Oyj',
'OZ',
'p',
'P',
'P2P',
'pa',
'pA',
'Pa',
'PA',
'PAA',
'PAB',
'PABX',
'PAC',
'PACOM',
'PADD',
'PADI',
'PAEK',
'PAFC',
'PAH',
'PAHO',
'PAI',
'PAK',
'Pakistan',
'PAL',
'PAM',
'Pamida',
'PAMELA',
'pan',
'PAN',
'PANS',
'PAO',
'PAOK',
'PAPI',
'PAR',
'PARC',
'PAROS',
'PARP',
'PARWIG',
'PAS',
'PASA',
'PASGT',
'PASR',
'PATH',
'PAWS',
'PAYD',
'PAYE',
'Pb',
'PB',
'PBA',
'PBB',
'PBCK',
'PBD',
'PBDE',
'PBI',
'PBN',
'PBOH',
'PBR',
'PBS',
'PBRT',
'PBT',
'PBX',
'pC',
'PC',
'PCAS',
'PCB',
'PCC',
'PCCB',
'PCCIP',
'PCE',
'PCI',
'PCIe',
'PCL',
'PCMCIA',
'PCN',
'PCP',
'PCPI',
'PCR',
'PCSSD',
'PCT',
'PCTE',
'PCZ',
'Pd',
'PD',
'PD',
'PDA',
'PDD',
'PDF',
'PDG',
'PDL',
'PDN',
'PDP',
'PDQ',
'PDRY',
'PDT',
'PDU',
'PE',
'PE',
'PEBCAC',
'PEBKAC',
'PEEK',
'PEET',
'PEGIDA',
'PEI',
'PEK',
'PEN',
'PEO',
'PEPFAR',
'PER',
'perl',
'PES',
'PEST',
'PET',
'PETA',
'PETE',
'PETP',
'pF',
'PF',
'PFD',
'PFE',
'PFLAG',
'PFIY',
'PFO',
'PfP',
'pg',
'Pg',
'PG',
'PGCE',
'PGD',
'PGENI',
'PGH',
'PGK',
'PGM',
'PGP',
'pH',
'PH',
'PHA',
'PHL',
'PHQ',
'PHP',
'pi',
'Pi',
'PI',
'PIB',
'PIC',
'PICNIC',
'PICS',
'PID',
'PIF',
'PIG',
'PIM',
'PIN',
'PINF',
'PIPA',
'PIR',
'PIRA',
'PIREPS',
'PITA',
'pJ',
'PJ',
'PJHQ',
'PJs',
'K',
'PK',
'PKA',
'PKE',
'PKI',
'PKM',
'PKMS',
'PKMT',
'PKR',
'PKT',
'PKTM',
'PKU',
'pl',
'pL',
'PL',
'PLAF',
'PLAID',
'PLB',
'PLC',
'PLCC',
'PLD',
'PLEBS',
'PLF',
'PLGR',
'pli',
'PLI',
'PLIF',
'PLK',
'PLKK',
'PLL',
'PLM',
'PLMBA',
'Pln',
'PLN',
'PLO',
'PLOKTA',
'PLP',
'PLS',
'PLUTO',
'PLW',
'pm',
'Pm',
'PM',
'PMC',
'PMCS',
'PME',
'PMI',
'PMID',
'PMMA',
'PMOS',
'PMS',
'PMSC',
'PMSL',
'PMT',
'pN',
'PN',
'PNAC',
'PNC',
'PNF',
'PNG',
'PNP',
'PNS',
'PNVS',
'PNH',
'Po',
'PO',
'PO',
'PO',
'POCD',
'pod',
'POD',
'POE',
'POETS',
'POFF',
'POG',
'POGO',
'POI',
'POK',
'pol',
'POL',
'POLAD',
'POM',
'POMDP',
'POP',
'por',
'POR',
'POS',
'POSH',
'POSSLQ',
'POT',
'POTS',
'POV',
'POW',
'pp',
'PP',
'PPA',
'PPASC',
'PPASSCCATAG',
'PPCLI',
'PPG',
'PPL',
'PPLI',
'ppm',
'PPM',
'PPM',
'PPO',
'PPP',
'PPQ',
'PPQN',
'PPQT',
'PPS',
'PPV',
'PQ',
'Pr',
'Pr',
'PR',
'Prb',
'PRBO',
'PRC',
'PRD',
'PRFG',
'PRI',
'PRK',
'PRM',
'PRN',
'PRT',
'PRY',
'ps',
'pS',
'Ps',
'PS',
'PooS',
'PSA',
'PSC',
'PSE',
'PSG',
'PSI',
'PSI',
'PSO',
'PSS',
'PST',
'PSU',
'PSV',
'PsyOp',
'pt',
'pT',
'Pt',
'PT',
'PTA',
'PTAL',
'PTAN',
'PTES',
'PTFE',
'PTI',
'PTL',
'PTO',
'PTSD',
'PTUI',
'Pu',
'PU',
'Pub',
'PUG',
'PUN',
'PUP',
'PUR',
'pus',
'PUS',
'pV',
'PV',
'PVC',
'PvE',
'PvP',
'PVR',
'PvZ',
'pW',
'PW',
'petawatt',
'PWA',
'PWBA',
'PwC',
'PWC',
'PWL',
'PWLC',
'PWP',
'PWR',
'PWV',
'PX',
'PY',
'PYF',
'PYG',
'PYMWYMI',
'PZ',
'Q1',
'Q2',
'Q3',
'Q4',
'QA',
'Qantas',
'QAP',
'QAR',
'QAT',
'QB',
'QC',
'QCA',
'QCD',
'QD',
'QDOS',
'QDR',
'QDRO',
'QED',
'QEH',
'QFE',
'QID',
'QIP',
'QK',
'QLD',
'QM',
'QNH',
'QPR',
'QPS',
'QR',
'QS',
'QSCAT',
'QSL',
'QSO',
'QSTAG',
'QT',
'qu',
'Quango',
'Quasar',
'QUB',
'que',
'QUIET',
'qv',
'QVC',
'QWG',
'QWGAOR',
'QZ',
'R',
'R2I',
'R3P',
'Ra',
'RA',
'RA',
'RAA',
'RAAF',
'Rabobank',
'RAC',
'RACK',
'Racon',
'RADA',
'Radar',
'RADB',
'RADb',
'RadB',
'RADC',
'RAE',
'RAF',
'RAG',
'RAGBRAI',
'RAM',
'Ramark',
'RAOC',
'RAP',
'RARDE',
'RARE',
'RASC',
'RATELO',
'Rb',
'RB',
'RBAR',
'RBCI',
'RBI',
'RBOC',
'RC',
'RCA',
'RCAF',
'RCAR',
'RCC',
'RCCO',
'RCHA',
'RCMP',
'RCRA',
'RCS',
'RCT',
'RCZ',
'RD',
'RDA',
'RDD',
'RDDL',
'RDECOM',
'RDM',
'RDO',
'RDS',
'RDT',
'RDV',
'Re',
'RE',
'REACH',
'REACT',
'REAM',
'RECCE',
'REEF',
'REM',
'REME',
'RERC',
'RES',
'RESNA',
'REU',
'Rf',
'RF',
'RFA',
'RFC',
'RFD',
'RFE',
'RFEF',
'RFF',
'RFI',
'RFID',
'RFL',
'RFL',
'RFP',
'RFQ',
'RFT',
'RFU',
'Rg',
'RG',
'RGB',
'RGBI',
'Rh',
'RH',
'RHIC',
'RHLI',
'RHO',
'RHS',
'RHU',
'RHWR',
'RI',
'RI',
'RIAA',
'RIBA',
'RIF',
'RIFA',
'RILA',
'RIM',
'RIMVS',
'RINO',
'RIP',
'RIS',
'RISC',
'RISD',
'RISTA',
'RWH',
'RJD',
'RKBA',
'RKI',
'RKO',
'RL',
'RLP',
'RLS',
'RLY',
'RM',
'rm',
'RMA',
'RMC',
'RMC',
'RMDA',
'RMP',
'RMS',
'rn',
'Rn',
'RN',
'RN',
'RNA',
'RNAD',
'RNPS',
'RNZ',
'RNZI',
'ro',
'RO',
'ROA',
'ROC',
'ROD',
'ROE',
'roh',
'ROH',
'ROI',
'ROFLOL',
'ROK',
'ROL',
'ROM',
'ron',
'RON',
'ROP',
'ROPE',
'ROS',
'ROTC',
'ROU',
'ROV',
'ROW',
'ROWPU',
'ROZ',
'RP',
'RPA',
'RPD',
'RPF',
'RPG',
'RPL',
'rpm',
'RPM',
'RPN',
'RPO',
'rps',
'RPV',
'RQ',
'RRB',
'RRN',
'RRP',
'RRS',
'RRSP',
'RS',
'RSA',
'RSD',
'RSEQ',
'RSI',
'RSJ',
'RSN',
'RSPB',
'RSPCA',
'RSR',
'RSRE',
'RSRI',
'RSS',
'RSTA',
'RSVP',
'RT',
'RTA',
'RTBF',
'RTCA',
'RTF',
'RTFM',
'RTG',
'RTGS',
'RTI',
'RTL',
'RTLS',
'RTP',
'RTS',
'RTT',
'RTV',
'ru',
'Ru',
'RU',
'RUB',
'RUC',
'run',
'rus',
'RUS',
'RUSI',
'RV',
'RVD',
'rw',
'RW',
'RWA',
'RWADA',
'RWF',
'RWR',
'RWS',
'Rx',
'RX',
'South',
'SA',
'SAA',
'SAC',
'SAD',
'SADL',
'SAE',
'SALT',
'SAM',
'Sambo',
'san',
'SAR',
'SARA',
'SART',
'SAS',
'SAT',
'SAW',
'SB',
'SBA',
'SBC',
'SBD',
'SBNO',
'SBP',
'SBS',
'SBW',
'SC',
'SCA',
'SCO',
'SCS',
'SCSI',
'SD',
'SDC',
'SDHC',
'SDXC',
'SE',
'SEC',
'SEM',
'SEN',
'SEP',
'SEU',
'SF',
'SFA',
'SFE',
'SG',
'SGC',
'SGS',
'SH',
'SHAC',
'SHO',
'SI',
'SIG',
'SIIT',
'SIMPLE',
'SIP',
'Siri',
'SIRI',
'SISO',
'SJ',
'SK',
'SL',
'SLA',
'SLOC',
'SLP',
'SLT',
'SM',
'SME',
'SMG',
'SMX',
'SMZ',
'SN',
'SNL',
'SNR',
'SO',
'SOA',
'SOAP',
'SOB',
'SoCon',
'SOD',
'SOE',
'SOF',
'SOGAT',
'SOM',
'SOP',
'SOR',
'SOS',
'Sp',
'SP',
'SPD',
'SPL',
'SR',
'SRAM',
'SRD',
'SRG',
'SRL',
'SS',
'SSA',
'SSC',
'SSD',
'SSDD',
'SSE',
'SSN',
'SSR',
'SSRI',
'SSRN',
'SSS',
'SST',
'ST',
'STA',
'STAR',
'STAT',
'STB',
'STD',
'STFU',
'STI',
'STP',
'STRICOM',
'SU',
'SUI',
'SV',
'SVD',
'SW',
'SWA',
'SWF',
'SWG',
'SWLABR',
'SWP',
'SXM',
'SZ',
'T',
'ta',
'Ta',
'TA',
'TAA',
'TAACOM',
'TAADCOORD',
'TAAR',
'TAC',
'TACAMO',
'TACAN',
'TACC',
'TACCO',
'TACCSF',
'TACDAR',
'TACP',
'TACSOP',
'TADIL',
'TADL',
'TADSS',
'tah',
'TAI',
'TAJ',
'TAJT',
'TALES',
'tam',
'TAN',
'TANJ',
'TANP',
'TANSTAAFL',
'TAPPS',
'TARDIS',
'TARP',
'TAROT',
'TAS',
'TASER',
'TASM',
'tat',
'TAT',
'TATP',
'TATT',
'Tb',
'TB',
'TBA',
'TBBPA',
'TBC',
'TBD',
'TBD',
'TBF',
'TBGRI',
'TBH',
'TBL',
'TBM',
'TBS',
'TBT',
'Tc',
'TC',
'TCA',
'TCAS',
'TCAA',
'TCCCS',
'TCD',
'TCG',
'TCHD',
'TCL',
'TCM',
'TCP',
'TCU',
'TCWMS',
'TCW',
'TD',
'TDA',
'TDL',
'TDM',
'TDMA',
'TDP',
'TDRS',
'te',
'Te',
'TE',
'TEAL',
'Team',
'TEAMS',
'TED',
'TEETH',
'tel',
'TEL',
'TELAR',
'TEMO',
'TENCAP',
'TERS',
'TES',
'TEST',
'TEU',
'TeVeS',
'TF',
'TFMPP',
'TFR',
'TFT',
'tg',
'Tg',
'TG',
'TGA',
'TGB',
'TGIF',
'tgk',
'tgl',
'TGO',
'TGT',
'TGV',
'th',
'Th',
'TH',
'tha',
'THA',
'THAAD',
'THB',
'THEGENTRY',
'THEL',
'THG',
'ti',
'Ti',
'TI',
'TIA',
'TIA',
'TIALD',
'TIBS',
'TIC',
'TID',
'TIGERS',
'TIM',
'TIMS',
'TINC',
'TINY',
'TIP',
'tir',
'tits',
'TJ',
'TJD',
'TJK',
'TJS',
'tk',
'TK',
'TKBL',
'TKL',
'TKM',
'tl',
'Tl',
'TLPM',
'TL',
'TLATOJL',
'TLA',
'TLAM',
'TLAR',
'TLC',
'TLD',
'TLM',
'TLS',
'Tm',
'Thulium',
'TM',
'TMA',
'TMAP',
'TMC',
'TMD',
'TMDK',
'TMI',
'TMJ',
'TMM',
'TMNT',
'TMO',
'TMP',
'TMPG',
'TMR',
'TMS',
'TMT',
'TMTOWTDI',
'TMZ',
'tn',
'TN',
'TNA',
'TND',
'TNO',
'TNT',
'to',
'TO',
'TOA',
'TOAD',
'TOBS',
'TOC',
'TOG',
'TOGS',
'TOMS',
'ton',
'TON',
'TOP',
'TOS',
'TOT',
'TOW',
'TP',
'TPE',
'TPI',
'TPO',
'TPS',
'TPTB',
'TQ',
'tr',
'TR',
'TRA',
'TRAC',
'TRACON',
'TRADOC',
'TRAIN',
'TRANSCOM',
'TRAP',
'TRASANA',
'TRD',
'TRIGAT',
'TRL',
'Trp',
'TRP',
'TRU',
'TRUEX',
'TRW',
'TRY',
'ts',
'Ts',
'TS',
'TSA',
'TSCA',
'TSEC',
'TSL',
'tsn',
'TSN',
'tso',
'TSO',
'TSOP',
'TSR',
'TSSIA',
'tt',
'TT',
'TTCT',
'TTD',
'TTO',
'TTFN',
'TTL',
'TTP',
'TTTEAF',
'TTY',
'TTYL',
'TTZ',
'TU',
'TUC',
'TUG',
'TUI',
'tuk',
'TUN',
'TUNS',
'Tur',
'TUR',
'TUSK',
'TUV',
'Tv',
'TV',
'TVA',
'TVG',
'TVR',
'TVS',
'tw',
'TW',
'TWAIN',
'TWD',
'TWE',
'twi',
'TWI',
'TWN',
'TWP',
'TWS',
'TWIP',
'TX',
'TXV',
'ty',
'TYT',
'TZ',
'TZA',
'TZC',
'TZS',
'U',
'UA',
'UAAP',
'UAB',
'UAE',
'UAH',
'UAR',
'UAV',
'UBC',
'UBIGEO',
'UBS',
'UCBSA',
'UCAV',
'UCF',
'UCI',
'UCLA',
'UCLES',
'UCR',
'UCS',
'UCSB',
'UCSD',
'UDAS',
'UDDI',
'UDP',
'UDT',
'UDTS',
'UE',
'UEFA',
'UEFI',
'UESAC',
'UFC',
'UFO',
'ug',
'UG',
'UGA',
'UGV',
'UGX',
'UHF',
'UHMW',
'UHRB',
'UIC',
'UID',
'uig',
'UIL',
'UIUC',
'UJTL',
'uk',
'UK',
'UKIP',
'ukr',
'UKR',
'UL',
'ULEB',
'ULF',
'UM',
'UMBC',
'UMC',
'UMCP',
'UMES',
'UMI',
'UMIST',
'UMK',
'UML',
'UMTS',
'UN',
'UNABOM',
'UNAIDS',
'UNaM',
'UNAM',
'UNAMSIL',
'UNCF',
'UNCLOS',
'UNCSGN',
'UNCTAD',
'UNDCP',
'UNDE',
'UNDP',
'UNEP',
'UNESCO',
'UNFCCC',
'UNFICYP',
'UNGEGN',
'UNHCR',
'UNICEF',
'UNICRI',
'UNIDO',
'UNIFEM',
'UNIS',
'UNLV',
'UNMIK',
'UNMOVIC',
'UNOSOM',
'UNPROFOR',
'UNRISD',
'UNSM',
'UNTAC',
'UNTAET',
'UNU',
'UP',
'UPC',
'UPdM',
'UPDM',
'UPF',
'UPI',
'UPN',
'UPS',
'UPTN',
'UPU',
'ur',
'UR',
'URBSFA',
'urd',
'URD',
'URI',
'URL',
'URU',
'URY',
'US',
'USA',
'USAADAC',
'USAARMC',
'USAAVNC',
'USAF',
'USAFSPC',
'USAIC',
'USAID',
'USAP',
'USAPATRIOT',
'USAREUR',
'USB',
'USC',
'USCG',
'USCINCSPACE',
'USD',
'USDA',
'USDI',
'USDOE',
'USDRE',
'USEC',
'USFL',
'USFWS',
'USGS',
'USIA',
'USJFCOM',
'USL',
'USMC',
'USMNT',
'USN',
'USNA',
'USNB',
'USNG',
'USNH',
'USNI',
'USNO',
'USNOFS',
'USNP',
'USNR',
'USNS',
'USNY',
'USNZ',
'USPS',
'USPTO',
'USR',
'USS',
'USSPACECOM',
'USSR',
'UST',
'USTRANSCOM',
'USWNT',
'UT',
'UTEP',
'UTM',
'UU',
'UUA',
'UUID',
'UUV',
'UV',
'UVM',
'UW',
'UWO',
'UWT',
'UWTG',
'UWW',
'UXO',
'UY',
'UYU',
'uz',
'UZ',
'uzb',
'UZB',
'UZS',
'V',
'VA',
'VACIS',
'VACIS',
'VANOS',
'VAO',
'VASIS',
'VAT',
'VB',
'VBA',
'VC',
'VCDS',
'vCJD',
'VCJCS',
'VCNO',
'VCR',
'VCofS',
'VCT',
'VD',
'VDFS',
'VDMOS',
'VDOT',
'VDR',
've',
'VE',
'VEB',
'VEEGA',
'ven',
'VEN',
'VERDI',
'VERITAS',
'VFA',
'VfD',
'VFL',
'VFM',
'VFMO',
'VFR',
'VFT',
'VFW',
'VG',
'VGB',
'VH1',
'VHF',
'vi',
'VI',
'VIA',
'VIC',
'VIDS',
'vie',
'VII',
'VIII',
'VIN',
'VIP',
'VIR',
'VIS',
'VISCII',
'VIT',
'VIVO',
'VJ',
'VK',
'VLA',
'VLAD',
'VLBA',
'VLF',
'VLIW',
'VLPFC',
'VLPMC',
'VLSF',
'VLSI',
'VLVS',
'VM',
'VMASC',
'VMF',
'VMF',
'VMN',
'VN',
'VND',
'VNIR',
'VNM',
'VNS',
'VOA',
'vo',
'VOC',
'VOIP',
'vol',
'VOLT',
'Volvo',
'VON',
'VOR',
'VOR',
'VOX',
'VP',
'VPN',
'VQ',
'VR',
'VRSG',
'VS',
'VSAT',
'VSHORAD',
'VSO',
'VSOP',
'VSR',
'VT',
'VTC',
'VTEC',
'VTOL',
'VU',
'VUB',
'VUT',
'VUV',
'VV',
'VVAF',
'VW',
'VZ',
'vzw',
'W',
'W3C',
'wa',
'WA',
'WAAC',
'WAAF',
'WAC',
'WACC',
'WACS',
'WAD',
'WAGGGS',
'WAGs',
'WAK',
'WAL',
'WAN',
'WAP',
'WAPIMA',
'WAR',
'WAsP',
'WASP',
'WAYP',
'Wb',
'WB',
'WBA',
'WBC',
'WBE',
'WBO',
'WC',
'WCBO',
'WCBP',
'WCL',
'WCS',
'WCW',
'WDC',
'WDWR',
'WDIN',
'WEBS',
'WEC',
'WEEE',
'WEF',
'WEM',
'WEU',
'WEZ',
'WF',
'WFC',
'WFD',
'WFF',
'WFP',
'WFZ',
'WFI',
'WG',
'WH',
'WHAM',
'WHI',
'WHIP',
'WHO',
'WHS',
'WI',
'WIA',
'WIIFM',
'WIMP',
'WIPO',
'WIPP',
'WIYN',
'WK',
'WKYP',
'WL',
'WLB',
'WLF',
'wln',
'WLOG',
'WM',
'WMANUS',
'WMD',
'WMI',
'WMO',
'WNA',
'WNF',
'WNL',
'WNIT',
'WNSD',
'WNW',
'wo',
'WO',
'WOF',
'WOFT',
'WOFTAM',
'WOI',
'wol',
'WOM',
'WOS',
'WOSM',
'WOT',
'WP',
'WPB',
'WPE',
'WPK',
'WPT',
'WR',
'WRAC',
'WRAIR',
'WRBWWRM',
'WRC',
'WRC',
'WRNS',
'WRVS',
'WRS',
'WRT',
'WRT',
'WRT',
'WRT',
'WRT',
'WRT',
'WRT',
'WRT',
'WRT',
'WRT',
'WRT',
'WS',
'WSC',
'WSDL',
'WSJ',
'WSL',
'WSM',
'WSMR',
'WSROC',
'WSSM',
'WST',
'WSTC',
'WSW',
'WTB',
'WTB',
'WTC',
'WTF',
'WTFH',
'WTH',
'WTO',
'WTS',
'WU',
'WUU2',
'WUBU2',
'WV',
'WVA',
'WVIAC',
'WVR',
'WVSC',
'WVU',
'WVUIT',
'WW',
'WWE',
'WWF',
'WWI',
'WWII',
'WWIII',
'WWJD',
'WWS',
'WWV',
'WWW',
'WWWF',
'WXO',
'WY',
'WYSIWYG',
'WYSIWYP',
'WZ',
'XAF',
'XAG',
'XAU',
'XBA',
'XBB',
'XBML',
'XC',
'XCBL',
'XCD',
'XD',
'Xe',
'XF',
'XFA',
'XFC',
'XFD',
'Xfe',
'XFF',
'XFG',
'XFH',
'XFN',
'XFR',
'XFT',
'XFW',
'XG',
'XGA',
'XGE',
'XGG',
'XGN',
'XGP',
'XGR',
'xh',
'xho',
'XHTML',
'XHR',
'XI',
'XII',
'XIII',
'XIV',
'XIX',
'XJ',
'XJL',
'XJS',
'XK',
'XKA',
'XKB',
'XKE',
'XKH',
'XKR',
'XKS',
'XKY',
'XL',
'XML',
'XMSF',
'XN',
'XNA',
'XNC',
'XNN',
'XNS',
'XNU',
'XO',
'XOF',
'XP',
'XPD',
'XPF',
'XPT',
'XQJ',
'XQP',
'XQU',
'XRML',
'XS',
'XSL',
'XTMH',
'XUL',
'XV',
'XVI',
'XVII',
'XVIII',
'XWB',
'XWF',
'XWM',
'XWN',
'XWS',
'XX',
'XXX',
'XYA',
'Xyl',
'XZA',
'XZN',
'y',
'Y',
'Y2K',
'yA',
'Ya',
'YA',
'YAC',
'YAFLA',
'YAP',
'YaBPr',
'YABSLAG',
'Yb',
'YB',
'yC',
'YC',
'YD',
'YDS',
'YE',
'YEM',
'YER',
'yF',
'YF',
'yg',
'Yg',
'Ygo',
'YGCO',
'yH',
'YH',
'YHS',
'YHBT',
'YHIH1',
'YHII',
'yi',
'Yi',
'YI',
'yid',
'Yippie',
'yJ',
'YJ',
'yK',
'YK',
'YKYFCW',
'yL',
'YL',
'ym',
'Ym',
'YM',
'YMCA',
'YMD',
'YMMD',
'YMMV',
'yN',
'YN',
'yo',
'YO',
'YOLO',
'yor',
'YPA',
'YPG',
'YPIO',
'YPO',
'ys',
'yS',
'Ys',
'YS',
'YSA',
'YSI',
'yT',
'YT',
'YTD',
'YTM',
'YTS',
'YTT',
'YU',
'YUG',
'Yuppie',
'yV',
'YV',
'yW',
'YW',
'YWCA',
'YWMV',
'YYH',
'z',
'Z',
'za',
'zA',
'ZA',
'ZAF',
'ZAMS',
'ZAR',
'ZAS',
'ZBT',
'zC',
'ZC',
'ZCM',
'ZCMK',
'ZCU'
]
