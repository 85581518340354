<mat-drawer-container class="drawer">
    <mat-drawer mode="side" opened>
        <div class="drawer-header">
            <h1 class="mat-h1">Actions</h1>

            <button mat-icon-button color="primary" *ngIf="actionsData && actions && actions.length !== 0"
                type="button" class="add-icon-button"
                angulartics2On="click"
                angularticsAction="Actions: add first action is clicked"
                (click)="addNewAction()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="no-actions" *ngIf="actionsData && actions && actions.length === 0 && !newAction">
            <span class="mat-body-1">No actions added</span>
            <button mat-stroked-button color="primary" type="button"
                angulartics2On="click"
                angularticsAction="Actions: add action is clicked"
                (click)="addNewAction()">
                <mat-icon>add</mat-icon>
                Add action
            </button>
        </div>
        <mat-action-list *ngIf="actionsData && actions && actions.length">
            <button mat-list-item *ngFor="let action of actions; let i = index"
                class="list-action-list-item"
                [ngClass]="{
                    'list-action-list-item_active': selectedAction && action.title === selectedAction.title,
                    'list-action-list-item_notSaved': action.id === ''
                }"
                (click)="switchActionView(action)">
                <span>{{ action.title }}</span>
                <mat-icon *ngIf="!action.id" fontSet="material-icons-outlined"
                    class="list-action-list-item__savingIcon">
                    save
                </mat-icon>
            </button>
        </mat-action-list>
        <mat-form-field *ngIf="newAction" appearance="fill" class="new-action-input">
            <input matInput autofocus="true" name="newActionTitle" #newActionTitle="matInput"
                required
                placeholder="Enter action name"
                [(ngModel)]="newAction.title"
                (keyup.enter)="handleAddNewAction()">
            <span *ngIf="actionNameError" class="action-error">{{actionNameError}}</span>
            <button mat-icon-button matSuffix
                (click)="undoAction()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </mat-drawer>

    <mat-drawer-content *ngIf="actionsData">
        <app-alert></app-alert>
        <app-breadcrumbs [crumbs]="getCrumbs(currentConnection.title || currentConnection.database)" class="actions-breadcrumbs"></app-breadcrumbs>
        <app-content-loader *ngIf="!actions"></app-content-loader>

        <div class="action" *ngIf="selectedAction && actions.length">
            <form (ngSubmit)="handleActionSubmetting()" class="action-settings">
                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="updatedActionTitle" name="action-title" required>
                </mat-form-field>

                <div class="actions-icon-selector">
                    <span>Icon</span>
                    <app-icon-picker class="icon-picker"
                        [icon]="selectedAction.icon"
                        [defaultIcons]="defaultIcons"
                        tooltip="Choose an icon"
                        (onFieldChange)="updateIcon($event)">
                    </app-icon-picker>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Action URL</mat-label>
                    <input matInput [(ngModel)]="selectedAction.url" name="action-url" required>
                    <!-- <mat-error *ngIf="sslCert.errors?.required && (sslCert.invalid && sslCert.touched)">SSL certificate should not be empty.</mat-error> -->
                </mat-form-field>

                <mat-radio-group
                    name="action-type"
                    [(ngModel)]="selectedAction.type">
                    <mat-label>Affects</mat-label>
                    <mat-radio-button value='single' class="radio-button_first" checked>
                        Single row
                    </mat-radio-button>
                    <mat-radio-button value='multiple' class="radio-button_second">
                        Multiple rows
                    </mat-radio-button>
                </mat-radio-group>

                <mat-checkbox name="action-confirmation" #confirmation="ngModel"
                    labelPosition="after"
                    class="confirmation-checkbox"
                    [(ngModel)]="selectedAction.requireConfirmation">
                    Request confirmation
                </mat-checkbox>

                <div class="action__actions">
                    <button mat-stroked-button color="warn" type="button"
                        (click)="handleRemoveAction()">
                        Delete
                    </button>

                    <button mat-flat-button color="primary" type="submit"
                        [disabled]="submitting || !updatedActionTitle">
                        Save
                    </button>
                </div>
            </form>

            <div class="code-snippet-box">
                <mat-form-field appearance="outline" class="lang-select">
                    <mat-label>Language</mat-label>
                    <mat-select [(ngModel)]="codeLangSelected">
                        <mat-option *ngFor="let lang of codeSnippets | keyvalue; let key = index; trackBy:trackByFn" [value]="lang.key">{{lang.value.langName}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <button type="button"
                    mat-icon-button
                    class="copy-button"
                    matTooltip="Copy mater password"
                    [cdkCopyToClipboard]="codeSnippets[codeLangSelected].snippet[selectedAction.type]"
                    (cdkCopyToClipboardCopied)="showCopyNotification(codeSnippets[codeLangSelected].langName + 'code snippet was copied to clipboard.')">
                    <mat-icon>content_copy</mat-icon>
                </button>

                <ngx-codemirror class="action-codeSnippet"
                    [(ngModel)]="codeSnippets[codeLangSelected].snippet[selectedAction.type]"
                    name="code-snippet"
                    [options]="{
                        lineNumbers: true,
                        lineWrapping: true,
                        matchBrackets: true,
                        mode: codeSnippets[codeLangSelected].mode,
                        theme: 'ttcn'
                    }"
                ></ngx-codemirror>
            </div>
        </div>

    </mat-drawer-content>
  </mat-drawer-container>
