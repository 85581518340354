<h1 mat-dialog-title>Are you sure?</h1>
<mat-dialog-content>
    <p class="mat-body">
        This action is permanent and irreversible, you will not be able to restore your account in the future
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close mat-dialog-close [disabled]="submitting">Cancel</button>
  <button mat-flat-button color="warn"
    [disabled]="submitting"
    (click)="deleteAccount()">
        Yes, delete
  </button>
</mat-dialog-actions>
