<div class="wrapper">
    <div class="skeleton">
        <div class="bone title"></div>
        <div class="bone form__field"></div>

        <div class="form__line">
            <div class="bone form__string"></div>
            <div class="bone form__button form__button_plan"></div>
        </div>

        <div class="form__line form__line_invite">
            <div class="bone form__title"></div>
            <div class="bone form__button form__button_invite"></div>
        </div>

        <div class="form__table">
            <app-placeholder-table-data class="mat-elevation-z4"></app-placeholder-table-data>
        </div>
    </div>
</div>