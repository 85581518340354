import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-company-invitation',
  templateUrl: './placeholder-company-invitation.component.html',
  styleUrls: ['./placeholder-company-invitation.component.css']
})
export class PlaceholderCompanyInvitationComponent {

}
