import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-company',
  templateUrl: './placeholder-company.component.html',
  styleUrls: ['./placeholder-company.component.css']
})
export class PlaceholderCompanyComponent {

}
