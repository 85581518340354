<div class="panels skeleton">
    <div class="panel">
        <div class="bone groupName"></div>
        <div class="bone groupAction"></div>
    </div>
    <div class="panel">
        <div class="bone groupName"></div>
        <div class="bone groupAction"></div>
    </div>
    <div class="panel">
        <div class="bone groupName"></div>
        <div class="bone groupAction"></div>
    </div>
</div>