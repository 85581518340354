import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-connection-settings',
  templateUrl: './placeholder-connection-settings.component.html',
  styleUrls: ['./placeholder-connection-settings.component.css']
})
export class PlaceholderConnectionSettingsComponent {

}
