<app-banner type="success">
    <h3 class='mat-headline'>Congrats!</h3>
    <p class="mat-body-1">
        You upgrade your plan successfully!
    </p>
    <p class="mat-body-1">
        Now your plan is <strong class="plan-name">{{newPlan}}</strong>.
    </p>
    <div class="error-actions">
        <a mat-stroked-button routerLink="/connections-list">Dashboard</a>
    </div>
</app-banner>