import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-table-view',
  templateUrl: './placeholder-table-view.component.html',
  styleUrls: ['./placeholder-table-view.component.css']
})
export class PlaceholderTableViewComponent {

}
