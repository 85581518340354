<h1 mat-dialog-title>Add user to <strong>{{ group.title }}</strong> group</h1>
<app-placeholder-add-user-dialog *ngIf="availableMembers === null"></app-placeholder-add-user-dialog>
<form *ngIf="availableMembers" #addUserForm="ngForm" (ngSubmit)="joinGroupUser()">
  <mat-dialog-content>
    <div *ngIf="availableMembers.length">
      <mat-form-field appearance="outline">
        <mat-label>Select user</mat-label>
          <mat-select name="email"
              [(ngModel)]="groupUserEmail">
              <mat-option *ngFor="let member of availableMembers" [value]="member.email">
                <span *ngIf="member.name">{{member.name}} (</span>{{member.email}}<span *ngIf="member.name">)</span>
              </mat-option>
          </mat-select>
      </mat-form-field>
      <p class="mat-body-2">
        Add users to the
        <a routerLink="/company" class="link" mat-dialog-close>
          Company</a> before assigning them to a group.
      </p>
    </div>
    <p *ngIf="availableMembers.length === 0" class="mat-body-2">
      All your company members are already in this group. To add a new one first add them to your company.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-flat-button mat-dialog-close>Cancel</button>
    <button *ngIf="availableMembers.length; else companyButton"
      mat-flat-button color="primary"
      [disabled]="submitting || addUserForm.form.invalid">
      Add
    </button>
    <ng-template #companyButton>
      <a mat-flat-button mat-dialog-close
        color="primary"
        routerLink="/company">
        Open Company page
    </a>
    </ng-template>
  </mat-dialog-actions>
</form>
