<h1 mat-dialog-title>Connection error</h1>
<mat-dialog-content class="mat-body">
    <p>Connection does not exist or credentials are incorrect.</p>
    <strong>Error: {{data.errorMessage}}</strong>
</mat-dialog-content>
<mat-dialog-actions class="connection-error__actions" align="end">
    <button mat-button mat-dialog-close="change" color="primary">
        Keep editing
    </button>
    <button *ngIf="data.dbCreds && data.dbCreds.id; else addButton"
        mat-button mat-dialog-close color="primary"
        (click)="editConnection()">
        Save anyway
    </button>
    <ng-template #addButton>
        <button mat-button mat-dialog-close color="primary"
            (click)="createConnection()">
            Add anyway
        </button>
    </ng-template>
    <button mat-flat-button color="accent" (click)="openIntercome()">Chat with support</button>
</mat-dialog-actions>