import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-user-group',
  templateUrl: './placeholder-user-group.component.html',
  styleUrls: ['./placeholder-user-group.component.css']
})
export class PlaceholderUserGroupComponent {

}
