import { Component } from '@angular/core';

@Component({
  selector: 'app-placeholder-row-edit',
  templateUrl: './placeholder-row-edit.component.html',
  styleUrls: ['./placeholder-row-edit.component.css']
})
export class PlaceholderRowEditComponent {

}
