<h1 mat-dialog-title>Create group of users</h1>
<form #addGroupForm="ngForm" (ngSubmit)="addGroup()">
    <mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>Enter group title</mat-label>
            <input matInput [(ngModel)]="groupTitle" name="title" #title="ngModel" required>
            <mat-error *ngIf="title.errors?.required && (title.invalid && title.touched)">Title should not be empty.</mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-flat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button color="primary"
            [disabled]="submitting || addGroupForm.form.invalid">
                Create
        </button>
    </mat-dialog-actions>
</form>