<mat-form-field class="password-form-field" appearance="outline">
    <mat-label>{{normalizedLabel}}</mat-label>
    <input matInput type="password" autocomplete="new-password" name="{{label}}-{{key}}"
        [required]="required" [disabled]="disabled || clearPassword" [readonly]="readonly"
        [(ngModel)]="value" (ngModelChange)="onFieldChange.emit($event)">
    <mat-hint>To keep password the same keep this field blank.</mat-hint>
</mat-form-field>
<mat-checkbox class="password-checkbox"
    [disabled]="value !== '' && value !== null "
    [(ngModel)]="clearPassword"
    (ngModelChange)="onClearPasswordChange()">
    Clear password
</mat-checkbox>
